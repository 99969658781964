import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";


const KMC_Department = () => {
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };

  const fileDownload = (fileStoreId) => {
    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`;
    axios
      .post(url, { fileId: fileStoreId, userId: "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = "data:application/pdf;base64," + base64;

          let html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            '<iframe src="' +
            pdfData +
            '"></iframe>' +
            "</body></html>";
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      });
  };
const [MoreBtnServices, setMoreBtnServices] = React.useState(false);
const [FinancialServices, setFinancialServices] = React.useState(false);
const [FinancialOneServices, setFinancialOneServices] = React.useState(false);
const [FinancialTwoServices, setFinancialTwoServices] = React.useState(false);
const [FinancialThreeServices, setFinancialThreeServices] = React.useState(false);
const [FinancialFourServices, setFinancialFourServices] = React.useState(false);
const [FinancialFiveServices, setFinancialFiveServices] = React.useState(false);
const [FinancialSixServices, setFinancialSixServices] = React.useState(false);
const [FinancialSevenServices, setFinancialSevenServices] = React.useState(false);

const FinancialServicesToggle = () => {
  setFinancialServices(!FinancialServices);
};
const MoreBtnServicesToggle = () => {
  setMoreBtnServices(!MoreBtnServices);
};
const FinancialOneServicesToggle = () => {
  setFinancialOneServices(!FinancialOneServices);
};
const FinancialTwoServicesToggle = () => {
  setFinancialTwoServices(!FinancialTwoServices);
};
const FinancialThreeServicesToggle = () => {
  setFinancialThreeServices(!FinancialThreeServices);
};
const FinancialFourServicesToggle = () => {
  setFinancialFourServices(!FinancialFourServices);
};
const FinancialFiveServicesToggle = () => {
  setFinancialFiveServices(!FinancialFiveServices);
};
const FinancialSixServicesToggle = () => {
  setFinancialSixServices(!FinancialSixServices);
};
const FinancialSevenServicesToggle = () => {
  setFinancialSevenServices(!FinancialSevenServices);
};
  const { t, i18n, ready } = useTranslation();
  return (
    <>
      {/* Slider */}
      <section id="hero" className="hero hero2">
        <div
          className="social-media wow slideInDown"
          style={{ visibility: "visible", animationName: "slideInDown" }}
        >
          <div className="facebook" rel="ebInfo" title="facebook">
            <a
              href="https://www.facebook.com/KolkataMunicipalCorporation.official"
              target="_blank"
            >
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a
              href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp"
              target="_blank"
            >
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>

        <div className="carousel-item active">
          <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
          <div className="container">
            {" "}
            <div className="section-main-heading2">
              {" "}
              <h4 className="fontSizeClass"> {t("KMC_DEPARTMENTS")}</h4>
            </div>
          </div>
        </div>
      </section>
      {/* End Slider */}
      <section className="section inner-pad1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="site-menu  min-height-400">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                       <Link  className="nav-link fontSizeClass active1"  to="/KMC_Department" > {t("KMC Department")} </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link  fontSizeClass"
                        to="/AboutUs"
                      >
                        {t("Vision")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Overview">
                        {t("Overview")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fontSizeClass"
                        onClick={(e) => fileDownload("1849")}
                      >
                        {t("KMC Structure")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/KMCFunctions"
                      >
                        {t("KMC Functions")}
                      </Link>
                    </li>
                    
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/EKolkataCenter"
                      >
                        {t("e-Kolkata Centers")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link fontSizeClass"
                        to="/Basic_statestics"
                      >
                        {t("Basic Statistics")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Emblem">
                        {t("Emblem")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/KMCBudget">
                        {t("KMC Budget")}
                      </Link>
                    </li>
                    <li className="nav-item-list nav_dropdown">
                      <button
                        type="button"
                        className="btn"
                        data-toggle="collapse"
                        data-target="#demo"
                      >
                        KMC Financial Statements{" "}
                        <i className="bi bi-chevron-down" />
                      </button>
                      <div id="demo" className="collapse">
                        <li className="nav-item-list nav_dropdown">
                          <button type="button" className="btn" data-toggle="collapse" data-target="#demo1">
                            2012- 2013
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo1" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1850")}>
                                {t("Receipt and Payment Statement 2012-13")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1851")}>
                                {t("Income & Expenditure 2012-13")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1852")}>
                                {t("Balance Sheet 2012-13")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo2"
                          >
                            2013- 2014
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo2" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1853")}>
                                {t("Annual Financial Statement 2013-14")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1854")}>
                                {t("Audit Certificate for ELA 2013-14")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo3"
                          >
                            2014- 2015
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo3" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1855")}>
                                {t("Receipt and Payment Statement 2014-15")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1858")}>
                                {t("Income & Expenditure 2014-15")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1857")}>
                                {t("Balance Sheet 2014-15")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1856")}>
                                {t("Audit Report 2014-15")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo4"
                          >
                            2015- 2016
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo4" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1859")}>
                                {t("Receipt and Payment Statement 2015-16")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1860")}>
                                {t("Income & Expenditure 2015-16")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1861")}>
                                {t("Balance Sheet 2015-16")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1862")}>
                                {t("Audit Report 2015-16")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo5"
                          >
                            2016- 2017
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo5" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1863")}>
                                {t("Receipt and Payment Statement")}{" "}
                                {t("2016-17")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1864")}>
                                {t("Income & Expenditure")} {t("2016-17")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1865")}>
                                {t("Balance Sheet 2016-17")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1866")}>
                                {t("Audit Report 2016-12")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo6"
                          >
                            2017-2018
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo6" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1867")}>
                                {t("Receipt and Payment Statement")} 2017-18
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1868")}>
                                {t("Income & Expenditure")} 2017-18
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a> Balance Sheet 2017-18 </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1869")}>
                                {t(
                                  "Letter of MC to Examiner of Local Accounts, WB"
                                )}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1870")}>
                                {t("Audit Report")} 2017-18
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo7"
                          >
                            2018-2019
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo7" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1871")}>
                                {t("Receipt and Payment Statement 2018-19")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1872")}>
                                {t("Income & Expenditure 2018-19")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1873")}>
                                {t("Balance Sheet 2018-19")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1874")}>
                                {t("Audit Report 2018-19")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target="#demo8"
                          >
                            2019-2020
                            <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo8" className="collapse">
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1875")}>
                                {t("Receipt and Payment Statement 2019-20")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1876")}>
                                {t("Income & Expenditure 2019-20")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1877")}>
                                {t("Balance Sheet 2019-20")}
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a onClick={(e) => fileDownload("1878")}>
                                {t("Audit Report 2019-20")}
                              </a>
                            </li>
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button type="button" className="btn"  data-toggle="collapse" data-target="#demo9">
                            2020-2021 <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo9" className="collapse">
                            <li>
                            <a className="" target="_blank" href='https://www.kmcgov.in/KMCPortal/downloads/Receipt_Payments_2020_2021_05_12_2023.pdf'> {t("Receipt and Payment Statement 2020-21")} </a>               
                            </li>
                            <li>
                            <a className="" target="_blank" href='https://www.kmcgov.in/KMCPortal/downloads/Income_Expenditure_2020_2021_05_12_2023.pdf'> {t("Income & Expenditure 2020-21")} </a>               
                            </li>
                            <li>
                            <a className="" target="_blank" href='https://www.kmcgov.in/KMCPortal/downloads/Balance_Sheet_2020_2021_05_12_2023.pdf'> {t("Balance Sheet 2020-21")} </a>               
                            </li>
                           
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button type="button" className="btn" data-toggle="collapse" data-target="#demo10" >
                            2021-2022 <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo10" className="collapse">
                            <li>
                            <a className="" target="_blank" href=''> {t("Receipt and Payment Statement 2021-22")} </a>               
                            </li>
                            <li>
                            <a className="" target="_blank" href=''> {t("Income & Expenditure 2021-22")} </a>               
                            </li>
                            <li>
                            <a className="" target="_blank" href=''> {t("Balance Sheet 2021-22")} </a>               
                            </li>                          
                          </div>
                        </li>
                        <li className="nav-item-list nav_dropdown">
                          <button type="button" className="btn" data-toggle="collapse" data-target="#demo10" >
                            2022-2023 <i className="bi bi-chevron-down" />
                          </button>
                          <div id="demo10" className="collapse">
                            <li>
                            <a className="" target="_blank" href=''> {t("Receipt and Payment Statement 2022-23")} </a>               
                            </li>
                            <li>
                            <a className="" target="_blank" href=''> {t("Income & Expenditure 2022-23")} </a>               
                            </li>
                            <li>
                            <a className="" target="_blank" href=''> {t("Balance Sheet 2022-23")} </a>               
                            </li>                          
                          </div>
                        </li>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="container">
                <div className="row">
                  <div className="inner-title2">
                    <h3 className="fontSizeClass"> {t("KMC_DEPARTMENTS")}</h3>
                  </div>
                  <div className="col-lg-12 about-head1">
                    <p className="fontSizeClass">{t("KMC_DEPARTMENTS_text")}</p>
                    <div class="dropdown my-2">
                      <div className="more-but">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-toggle="collapse"
                          data-target="#demo6"
                        >
                          {t("More")}
                          <i className="bi bi-chevron-down" />
                        </button>
                        <div id="demo6" className="collapse">
                          <p className="fontSizeClass">
                            {t("KMC_DEPARTMENTS_text1")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mayor-table2 mayor-table depart my-2">
                    <table className="table mayor-table table-bordered kmc-department">
                      <thead>
                        <tr className="table-warning table-bor-2">
                          <th className="bd-wid" scope="col">
                            {" "}
                            {t("Sl.No")}.{" "}
                          </th>
                          <th scope="col"> {t("Department")} </th>
                          <th scope="col"> {t("Key Activities")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row"> 1 </td>
                          <td>
                            <Link
                              className="nav-link fontSizeClass"
                              to="/KMC_Advertisement"
                            >
                              {" "}
                              {t("Advertisement")}{" "}
                            </Link>
                          </td>
                          <td>
                            {" "}
                            {t("Overall monitoring of outdoor advertisement")}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 2 </td>
                          <td>
                            <Link
                              className="nav-link fontSizeClass"
                              to="/Amusement"
                            >
                              {" "}
                              {t("Amusement")}{" "}
                            </Link>
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Issue of new amusement license, renewal letter and monitoring functions"
                            )}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 3 </td>
                          <td>
                            <Link
                              to="/Assessment_Collection_Assessment_Home"
                              class="nav-link"
                            >
                              {" "}
                              {t("Assessment & Collection")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {t(
                              "Assessment of land and buildings, Mutation and Collection of Property Tax"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 4 </td>
                          <td>
                            <Link
                              to="/Building_Sanction_Building_Home"
                              class="nav-link"
                            >
                              {" "}
                              {t("Building")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Scrutinizing building plan, checking unauthorized construction and routine inspection"
                            )}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 5 </td>
                          <td>
                            <Link
                              className="nav-link fontSizeClass"
                              to="/KMC_Bustee_services"
                            >
                              {" "}
                              {t("Bustee Services")}{" "}
                            </Link>
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Carry out physical and social infrastructure development in the bustees of the City"
                            )}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 6 </td>
                          <td>
                            <Link to="/Car_Parking_Home" class="nav-link">
                              {" "}
                              {t("Car Parking")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Responsible for arranging for parking spaces"
                            )}.{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 7 </td>
                          <td>
                            <Link
                              to="/KMC_Central_records_Home"
                              class="nav-link"
                            >
                              {" "}
                              {t("Central Records")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "To maintain all old records such as birth and death registration, sanctioned building plans, assessment registers, etc"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 8 </td>
                          <td>
                            <Link
                              to="/CH_VS_Dept_Download_Forms"
                              class="nav-link"
                            >
                              {" "}
                              {t("Ch. VS Department")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Chief Valuer and Surveyor maintains records of all immovable assets of KMC and performs tasks requiring verification or information from those"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 9</td>
                          <td>
                            <Link to="/Kmc_school_home" class="nav-link">
                              {" "}
                              {t("Education")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Imparting primary education and interacting with Government agencies and NGO’s"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 10 </td>
                          <td>
                            <Link to="/" class="nav-link">
                              {" "}
                              {t("Election Office")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t("Conducts elections of the Corporation")}.{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 11 </td>
                          <td>
                            <Link to="/Electricity_Dept" class="nav-link">
                              {" "}
                              {t("Electricity")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Carries out works related to Electricity supply in the Corporation area"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 12 </td>
                          <td>
                            <Link to="/Engineering_Home" class="nav-link">
                              {" "}
                              {t("Engineering (Civil)")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Building and repairing of roads, removal of chokages, bustee development work, construction of public conveniences, etc"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 13 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t("Entally workshop")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Production, repairing of different products required by various departments of KMC"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 14 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t("Estate")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Survey and maintain records of the land and building owned by the KMC"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 15 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t("Finance and accounts")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Carry out accounting of the activities of the Corporation and handling of the treasury"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 16 </td>
                          <td>
                            <Link to="/Health_Dept" class="nav-link">
                              {" "}
                              {t("Health")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Provide preventive, curative and promotive health facilities like vaccination etc"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 17 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t("Info. & public relations")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "To maintain public relation, issue Advertisement for print and electronic media, organizing press conferences and coverage of various events"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 18 </td>
                          <td>
                            <Link to="/IT_Dept" class="nav-link">
                              {" "}
                              {t("Information Technology")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "IT department is responsible for development and implementation of software across all departments of KMC. This includes specific initiatives like E-Kolkata Centres, Web Portal, ERP, MAS, KMC-NET, Data Centre, Disaster Recovery Centre etc"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 19 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t("Institute of Urban Management")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Impart training on Computer, Administrative and work based training to the employees of KMC"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 20 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t("Internal Audit")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Scrutinize and vet the audit replies given by the functional departments to the statutory auditors and conduct specific audits (i.e. special audit, Investigation, enquiries etc) assigned by the MC, JMC, etc"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 21 </td>
                          <td>
                            <Link to="/Law_Dept" class="nav-link">
                              {" "}
                              {t("Law")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Deal with legal matters and provide legal opinion to the different departments of KMC"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 22 </td>
                          <td>
                            <Link
                              to="/Certificate_of_Enlistment"
                              class="nav-link"
                            >
                              {" "}
                              {t("License")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Issue of new trade licenses, Renewal of old/expired trade licenses, Issuing temporary licenses, raising Demands for Trade License, etc. in relation to trade licenses"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 23 </td>
                          <td>
                            <Link to="/Lighting_Home" class="nav-link">
                              {" "}
                              {t("Lighting")}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Operation and maintenance of street lights, installation of new street lighting systems, maintain lighting of markets and institutions e.g. Chief Municipal office"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 24 </td>
                          <td>
                            <Link to="/KMC_market_list" class="nav-link">
                              {" "}
                              {t("Market")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Construction and maintenance of Municipal Markets"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 25 </td>
                          <td>
                            <Link to="/portal" class="nav-link">
                              {" "}
                              {t("Municipal Secretary’s department")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Carry out secretarial functions of the Corporation"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 26 </td>
                          <td>
                            <Link to="/Mpl_Secretary_Dept" class="nav-link">
                              {" "}
                              {t("Municipal Service Commission")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t("Carry out the recruitment for the Corporation")}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 27 </td>
                          <td>
                            <Link to="/Parks_and_gardens" class="nav-link">
                              {" "}
                              {t("Parks & Squares")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Looks after the matters related to parks and squares in the city"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 28 </td>
                          <td>
                            <Link to="/Personnel_Dept" class="nav-link">
                              {" "}
                              {t("Personnel")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Initiating promotion, transfer proposal and attend court cases related to personnel matters"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 29 </td>
                          <td>
                            <Link
                              to="/Town_Planning_Development_Dept.js"
                              class="nav-link"
                            >
                              {" "}
                              {t("Planning & Development")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Initiate new projects, carry out Technical (only for non commercial projects) and financial feasibility studies, Monitor implementation of projects"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 30 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t("Printing")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Print all types of forms, publications, registers and schedule books of the Corporation"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 31 </td>
                          <td>
                            <Link
                              to="/Environment_Heritage_Dept.js"
                              class="nav-link"
                            >
                              {" "}
                              {t("Environment & Heritage Department")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Looks after issues like Geographical Information System, water bodies conservation, heritage conservation"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 32 </td>
                          <td>
                            <Link to="/Roads_Dept.js" class="nav-link">
                              {" "}
                              {t("Roads and Asphaltum")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            <Link to="/portal" class="nav-link">
                              {t(
                                "Carrying out construction of new roads, under pass"
                              )}
                              ..
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 33 </td>
                          <td>
                            <Link
                              to="/Sewerage_Drainage_Dept.js"
                              class="nav-link"
                            >
                              {" "}
                              {t("Sewerage & Drainage")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Manage and maintain the central sewerage and drainage lines under all major / arterial roads"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 34 </td>
                          <td>
                            <Link
                              to="/Social_Welfare_Urban_Dept.js"
                              class="nav-link"
                            >
                              {" "}
                              {t(
                                "Social Welfare & Urban Poverty Alleviation Department"
                              )}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Looks after social sector issues like family welfare, women empowerment, employment generation etc"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 35 </td>
                          <td>
                            <Link to="/Solid_Waste_Dept.js" class="nav-link">
                              {" "}
                              {t("Solid Waste Management")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Sweep streets, collect solid waste from households and collection points and transport to the dumping yard"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 36 </td>
                          <td>
                            <Link to="/Water_Supply_Dept.js" class="nav-link">
                              {" "}
                              {t("Supply")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Material planning, procurement and storage of materials"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 37 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t(
                                "Material planning, procurement and storage of materials"
                              )}
                              .
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Takes care of overall planning of the city"
                            )}.{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 38 </td>
                          <td>
                            <Link to="/Treasury_Dept.js" class="nav-link">
                              {" "}
                              {t("Treasury Department")}{" "}
                            </Link>{" "}
                          </td>
                          <td> {t("Takes care of treasury of KMC")}. </td>
                        </tr>
                        <tr>
                          <td scope="row"> 39 </td>
                          <td>
                            <Link to="" class="nav-link">
                              {" "}
                              {t("Vigilance")}{" "}
                            </Link>{" "}
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Investigate cases against employees on receipt of complaints from Councillors, citizens or any other source"
                            )}
                            .{" "}
                          </td>
                        </tr>
                        <tr>
                          <td scope="row"> 40 </td>
                          <td>
                            <Link
                              className="nav-link fontSizeClass"
                              to="/Water_Supply"
                            >
                              {" "}
                              {t("Water Supply")}{" "}
                            </Link>
                          </td>
                          <td>
                            {" "}
                            {t(
                              "Installation, operation and maintenance of water treatment plants, booster pumping stations and tubewells.Top"
                            )}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default KMC_Department