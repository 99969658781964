import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Mom_tvc  = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    <div className="carousel-item active">
<img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />      <div className="container">
        
        <div className="section-main-heading2">
          
          <h4 className="fontSizeClass">  </h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container " >
      <div className="row">
      <div className="col-lg-3">
          <div className="site-menu  min-height-400">
            <nav className="sidebar card py-2">
            <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <Link className="nav-link fontSizeClass" to='/'> {t("MOM - TVC")} </Link>
                </li>
               
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-lg-9">
            <div className='container'>
              <div className="inner-title2 bas">
                <h3 className="fontSizeClass"> {t("MOM - TOWN VENDING COMMITTEE")} </h3>
              </div>
              <div className="health-dept-table">
                <table className="table table-bordered officials">
                  <tbody>
                    <tr bgcolor="#302ba0" style={{ color: "white", fontWeight: "bold" }} >
                      <td className="text-white tble-brder-left"> Sl-No </td>
                      <td className="text-white"> MOM - TOWN VENDING COMMITTEE </td>                 
                    </tr>

                    <tr>
                      <td> 1 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_17_05_2024.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee 
                        (TVC) for Kolkata City, held on 03.04.2024 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 2 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_22_03_2024.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC)
                       for Kolkata City, held on 12.03.2024 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 3 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_07_02_2024.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City,
                       held on 07.02.2024 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 4 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_11_01_2024.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City,
                       held on 11.01.2024 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 5 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_20_12_2023.pdf' target={'_blank'}> Minutes of the meeting of Town Vending 
                      Committee (TVC) for Kolkata City, held on 20.12.2023 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 6 </td>
                      <td> <a href='https://kmcgov.in/KMCPortal/downloads/TVC_04_10_2023.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for 
                      Kolkata City, held on 04.10.2023 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 7 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_11_08_2023.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City, 
                      held on 11.08.2023 in the Conference Room-II, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 8 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_07_07_2023.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for 
                      Kolkata City, held on 07.07.2023 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 8 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_16_03_2023.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City, held on 16.03.2023 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 9 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_01_12_2022.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City, 
                      held on 01.12.2022 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 10 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_01_11_2022.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City, 
                      held on 01.11.2022 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 11 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_01_11_2022.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City, 
                      held on 01.11.2022 in the Conference Room-I, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 12 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_18_04_2022.pdf' target={'_blank'}> Minutes of the meeting held at KMC on 01.06.2022 </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 13 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_18_04_2022.pdf' target={'_blank'}> Minutes of the meeting held on 18.04.2022 at 2 PM in the Council Chamber, CMO Building, KMC in respect of Town Vending Committee </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 14 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_23_02_2022.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City,
                       held on 23.02.2022 in the Council Chamber, KMC </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 15 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_14_01_2022.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City, held on 14.01.2022 in the Council Chamber </a>  
                      </td>
                    </tr>

                    <tr>
                      <td> 16 </td>
                      <td> <a href='https://www.kmcgov.in/KMCPortal/downloads/TVC_14_01_2022.pdf' target={'_blank'}> Minutes of the meeting of Town Vending Committee (TVC) for Kolkata City, held on 14.01.2022 in the Council Chamber </a>  
                      </td>
                    </tr>
                    
                    <tr>
                      <td> 17 </td>
                      <td> <a href='' target={'_blank'}>  Minutes of the meeting regarding the constitution of the Town Vending Committee for Kolkata city, held on 27.09.2018 in the Conference Hall 1 of the KMC </a>  
                      </td>
                    </tr>
                    
                    
                    
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default Mom_tvc