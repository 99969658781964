import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CBP_Home  = () => {
  const { t, i18n, ready } = useTranslation();
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
  return (
    <>  
    <section id="hero" className="hero hero2">
      <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
      
      <div className="carousel-item active">
        <img src="assets/img/inner-bg2.png" className="img-fluid" alt />
      </div>       	 		 
    </section>	
    <section className="section inner-pad1">
      <div className="container " >
        <div className="row">
          <div className="col-lg-3">
            <div className="site-menu  ">           
              <nav className="sidebar card py-2">
                <ul className="nav flex-column" id="nav_accordion">
                  <li className="nav-item"><Link className="nav-link active1 fontSizeClass" to='/CBP_Home'> {t("CBP Home")} </Link> </li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Vision_Strategy'> {t("Vision And Strategy")} </Link></li>
                  <li className="nav-item"><Link className="nav-link fontSizeClass" to='/Project_Faqs'> {t("FAQs")}</Link></li>
                </ul>
              </nav>	   
            </div>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="inner-title2">
                  <h3 className="fontSizeClass">{t("Capacity Building Programme (CBP) :: Brief Overview")}</h3>
                </div>
                <div className="col-lg-12 about-head1">
                  <h5 className="fontSizeClass"> <strong>{t("Background")}</strong> </h5>	
                  <p className="fontSizeClass">{t("The USD370 million, multi-agency project – the Kolkata Environmental Improvement Project (KEIP) was launched to provide an improved urban environment, equitable access to municipal services, and more effective municipal management in the city. The Asian Development Bank (ADB) has sanctioned a loan of USD220 million for KEIP, primarily in the added areas of KMC and in 100 slums")}.</p>
                </div>
                <div className="col-lg-12 about-head1">
                  <h5 className="fontSizeClass"> <strong>{t("Capacity Building Programme")}</strong> </h5>	
                  <p className="fontSizeClass">{t("The KEIP feasibility study identified the need for wider capacity building in KMC and proposed a Capacity Building Programme (CBP) under the KEIP. The Department for International Development (DFID), Government of U.K, agreed to provide upto £28.3m million over a six-year period to implement the CBP. The purpose of the programme is to transform KMC into an institution of excellence in city administration and good governance, providing sustainable and equitable urban services in the city, especially to the poor")}.</p>
                  <p className="fontSizeClass">{t("As part of the CBP, KMC has adopted a vision for itself; a vision to develop into ‘ an efficient, effective, equitable, citizen responsive, financially sustainable and transparent organisation, delivering quality service to its citizens’. The Vision Statement reflects KMC’s aspirations for itself, and for the city as a whole. The aim of the CBP is to facilitate the achievement of this Vision")}.</p>
                </div>
                <div className="col-lg-12 about-head1">
                  <h5 className="fontSizeClass"> <strong>{t("Detailed scope and project design")}</strong> </h5>	
                  <p className="fontSizeClass">{t("In November 2002, DFID appointed IPE (Infrastructure Professionals Enterprise, New Delhi) as the Inception Phase Consultants (IPC) to further refine the scope of the CBP identified under the ADB feasibility report and reach an agreement with KMC and other stakeholders regarding the design of the CBP. The IPC then detailed the scope of the CBP and proposed interventions under the following heads (consultants for which were selected through a competitive bidding process)")} -</p>
                </div>

                <div className="health-dept-table" style={{width:"100%"}}>
                  <table
                    width="95%"
                    border={0}
                    align="center"
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                      <tr
                        bgcolor="#302ba0"
                        tabIndex={0}
                        align="center"
                        valign="middle">
                        <td width="25%" className="text-white tble-brder-left">
                        {t("Sl.No")}.		
                        </td>
                        <td width="35%" className="text-white">
                        {t("Modules")}
                        </td>
                        <td width="40%" className="text-white tble-brder-right">
                        {t("Partners")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">
                         {t("D.M.C.(P)'S CIRCULAR NO./17/2004-2005")}</td>
                        <td align="center">{t("Organisational Development")}</td>
                        <td align="center">{t("A.F.Ferguson (AFF)")} </td>
                      </tr> 
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">CBP002</td>
                        <td align="center">{t("Computerisation")}</td>
                        <td align="center">{t("Tata Consultancy Services (TCS)")}</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">CBP003                        </td>
                        <td align="center">{t("Public Relation & Communication")}	</td>
                        <td align="center">.</td>
                      </tr>                      
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">CBP005 </td>
                        <td align="center">{t("Program Management")}<br />
                        {t("Implementation")}<br/>{t("Change Management and Governance")}<br/>
                        {t("Social Inclusion")}<br/>{t("Urban Planning")}</td>
                        <td align="center"></td>
                      </tr>                    
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">CBP006</td>
                        <td align="center">{t("Resource Mobilisation and Improved Financial Management")}</td>
                        <td align="center">{t("ICAI-Accounting Research Foundation (ICAI-ARF)")}</td>
                      </tr>
                      </tbody>
                  </table>
                </div>

                <div className="col-lg-12 about-head1">
                  <p className="fontSizeClass">{t("The CBP Cell (CBC), comprising of KMC officials, is the key implementation agency of the project. Nodal officers for each module have been nominated for coordinating the work of the respective module - interacting with consultants, finalizing recommendations and implementing the same. The CBP Cell is headed by a CBP Manager")}.</p>
                </div>

                <div className="health-dept-table" style={{width:"100%"}}>
                  <table
                    width="95%"
                    border={0}
                    align="center"
                    cellPadding={2}
                    cellSpacing={2}
                    className="table table-bordered officials" >
                    <tbody>
                      <tr tabIndex={0} align="left" valign="top">
                        <td width="40%" align="center">
                        {t("Oct 02")}
                        </td>
                        <td width="60%" align="center">
                        {t("Inception phase launched")}
                        </td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td align="center">{t("Mar 03")}</td>
                        <td align="center">{t("Detailed action plan developed")}</td>
                      </tr> 
                      <tr tabIndex={0} align="left" valign="top">
                        <td align="center">{t("Jun 03")}</td>
                        <td align="center">{t("Capacity Building Cell set up at KMC")}</td>
                      </tr>
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">{t("Jan-Jul 04")} </td>
                        <td align="center">{t("Consultants selected for technical and implementation advisory")}</td>
                      </tr>                      
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">{t("Apr 05")} </td>
                        <td align="center">{t("Annual review of progress by DFID")}</td>
                      </tr>                    
                      <tr tabIndex={0} align="left" valign="top">
                        <td  align="center">{t("Jul 05")} </td>
                        <td align="center">{t("Review of progress by KMC-MIC")}</td>
                      </tr>
                      </tbody>
                  </table>
                </div>
                
              </div> 
            </div>
          </div>
          </div>
      </div>
    </section>
      </>
  )
}

export default CBP_Home