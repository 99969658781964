import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Quick_Links = () => {
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const increaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style); 
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px =() => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
    var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
    var fontSize = parseFloat(style); 
    el[i].style.fontSize = (fontSize - 1) + 'px'; 
    }
  };
    const [OnlinePaymentService, setOnlinePaymentService] = React.useState(false);
    const [PropertyTaxService, setPropertyTaxService] = React.useState(false);
    const [PropertyTax1Service, setPropertyTax1Service] = React.useState(false);
    const [CheckHearingService, setCheckHearingService] = React.useState(false);

    const [MarketService, setMarketService] = React.useState(false);
    const [AmusementService, setAmusementService] = React.useState(false);
    const [PrintUnpaidService, setPrintUnpaidService] = React.useState(false);

    const OnlinePaymentServiceToggle = () => {
        setOnlinePaymentService(!OnlinePaymentService);
      };
      const PrintUnpaidServiceToggle = () => {
        setPrintUnpaidService(!PrintUnpaidService);
      };
    const PropertyTaxServiceToggle = () => {
    setPropertyTaxService(!PropertyTaxService);
    };
    const PropertyTax1ServiceToggle = () => {
        setPropertyTax1Service(!PropertyTax1Service);
        };
    const CheckHearingServiceToggle = () => {
        setCheckHearingService(!CheckHearingService);
        };
    const MarketServiceToggle = () => {
    setMarketService(!MarketService);
    };
    const AmusementServiceToggle = () => {
    setAmusementService(!AmusementService);
    };
    const { t, i18n, ready } = useTranslation();
  return (
    <>
  <>
  <section id="hero" className="hero hero2">
  <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
        <div className="facebook" rel="ebInfo" title="facebook">
          <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
            <img src="assets/img/face-book-1.png" alt="alt-image" />
          </a>
        </div>
        <div className="twitter" rel="ebInfo" title="Twitter">
          <a href="https://twitter.com/kmc_kolkata" target="_blank">
            <img src="assets/img/twitter1.png" alt="alt-image" />
          </a>
        </div>
        <div className="support" rel="ebInfo" title="KMC Support">
          <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
            <img src="assets/img/support.png" alt="alt-image" />
          </a>
        </div>
        <div className="whatsapp" rel="ebInfo" title="Whatsapp">
          <Link to="/Wathsapp" target="_blank">
            <img src="assets/img/social-media.png" alt="alt-image" />
          </Link>
        </div>
      </div>
    <div className="carousel-item active">
      <img src="assets/img/inner-bg2.png" className="img-fluid" alt="alt-image" />
      <div className="container">
        
        <div className="section-main-heading2">         
          <h4></h4>
        </div>
      </div>
    </div>
  </section>
  {/* End Slider */}
  <section className="section inner-pad1">
    <div className="container">
      <div className="row">
        <div className="inner-title2 quick-t">
          <h3> {t("Quick Links")} </h3>
        </div>

        <div className="col-lg-4">
          {/* <div className="site-menu quick1">
              <nav className="sidebar card">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item has-submenu">
                        <button type="button" className="btn " data-toggle="collapse" data-target="#demo">{t("Make Online Payment")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo" className="collapse quick-scrl"> 
                            <li className="nav-item">
                              <button type="button" className="btn" data-toggle="collapse"  data-target="#demo7">{t("Property Tax")}<i className="bi bi-chevron-down" /></button>
                                <div id="demo7" className="collapse"> 
                                      <li className="nav-item"><a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentAllUnpaidDemand.jsp" target='_blank'> {t("All Bill")} </a></li>
                                      <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/MiscellaneousSuspensePay.jsp'  target='_blank'> {t("Suspense")}</a></li>
                                      <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'  target='_blank'> {t("UAA Online Submission")} </a></li>
                                </div>
                            </li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp'  target='_blank'>{t("Building")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/KMCPortalLicensePaymentAction.do"  target='_blank'> {t("CE (Licence Dept.)")} </a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/SwmCeFeePayment.jsp"  target='_blank'> {t("SWM)")} </a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href="https://www.kmcgov.inKMCPortal/jsp/SorDemandPayment.jsp" target='_blank'> {t("Ch. Valuer & Surveyor")}</a></li>
                            <button type="button" className="btn " data-toggle="collapse"  data-target="#demo8">{t("Market")}<i className="bi bi-chevron-down" /></button>
                            <div id="demo8" className="collapse"> 
                            <li className="nav-item"><a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketPayment.jsp'> {t("Regular Demand")} </a></li>
                            <li className="nav-item"><a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketMiscPayDemand.jsp'> {t("Misclennious Demand")} </a></li>
                            </div>
                            <li className="nav-item"><Link className="nav-link fontSizeClass" to='/KMC_Advertisement'>{t("Advertisement")}</Link></li>
                            <button type="button" className="btn " data-toggle="collapse"  data-target="#demo9">{t("Amusement")}<i className="bi bi-chevron-down" /></button>
                              <div id="demo9" className="collapse"> 
                                  <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/KMCPortalAmusementPaymentAction.do' target='_blank'>{t("Amusement Fees (Renew/Casual/New)")}</a></li>
                                  <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target='_blank'>{t("Casual Demand Generation and payment")}</a></li>
                              </div>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCCarParkingPayment.jsp' target='_blank'>{t("Parking")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalWaterSupplyPayment.jsp' target='_blank'>{t("Water Supply")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp' target='_blank'>{t("Drainage")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteePayment.jsp' target='_blank'>{t("Bustee")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HawkerDemandPay.jsp' target='_blank'>{t("Hawker")}</a></li>
                        </div>
                    </li>
                  </ul>
              </nav>
          </div>*/}
          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <a className="nav-link fontSizeClass" href='https://kmc.wb.gov.in/parking/' target="_blank"> {t(" Check Online Parking Space Availability")} </a>
                  </li>
                </ul>
              </nav>
          </div>
          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <a className="nav-link fontSizeClass" href='https://www.s-parking.com/KMC/' target="_blank"> {t("Citizen Parking APP")} </a>
                  </li>
                </ul>
              </nav>
          </div>  
          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item has-submenu">
                      <button type="button" className="btn" data-toggle="collapse" data-target="#demo2">{t("Reprint e-receipt")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo2" className="collapse"> 
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCAssessmentCombinedReceiptReprint.jsp' target='_blank'>{t("All Bill")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PDDuplicateReceipt.jsp' target='_blank'>{t("Current PD, F/S")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp' target='_blank'>{t("Outstanding LOI")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LOIDuplicateReceipt.jsp' target='_blank'>{t("Waiver 2020")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MiscSuspDuplicateReceipt.jsp' target='_blank'>{t("Suspense")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/LicenseDuplicateReceipt.jsp' target='_blank'>{t("License")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/SwmCeDuplicateReceipt.jsp' target='_blank'>{t("SWM")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HawkerDuplicateReceipt.jsp' target='_blank'>{t("Hawker")}</a></li>                 
                      </div>
                </li>
                </ul>
              </nav>
          </div>        
          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BuildingEODBPlanCaseSearch.jsp' target="_blank"> {t(" EODB SANCTION LIST ")} </a>
                  </li>
                </ul>
              </nav>
          </div>         
          {/* <div className="site-menu quick2">
              <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion1">
                    <li className="nav-item has-submenu">
                      <button type="button" className="btn " data-toggle="collapse" data-target="#demo1">{t("Check Hearing Notice")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo1" className="collapse"> 
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNoticeSearch.jsp' target='_blank'>{t("Property Tax")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNotice.jsp' target='_blank'>{t("Building")}</a></li>
                      </div>
                    </li>
                  </ul>
              </nav>
        </div>*/}

          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/NagarBandhu.jsp' target='_blank'>  {t(" Nagar Bandhu Success Stories ")} </a>
                  </li>
                </ul>
              </nav>
          </div>
          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/NightParking.jsp' target='_blank'>  {t("  Online Permission for Night Parking  ")} </a>
                  </li>
                </ul>
              </nav>
          </div>
          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MATCauseList.jsp' target='_blank'>  {t("MAT Cause List")} </a>
                  </li>
                </ul>
              </nav>
          </div>
          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MatJudmentFileView.jsp' target='_blank'>  {t("MAT Judgement")} </a>
                  </li>
                </ul>
              </nav>
          </div>
          <div className="site-menu quick1">
              <nav className="sidebar card">
                <ul className="nav flex-column" id="nav_accordion">
                  <li>
                  <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/BuildingMbcResolutionViewAction.do?action=search_appeal' target='_blank'>  {t(" MBT Cause List")} </a>
                  </li>
                </ul>
              </nav>
          </div>
          <div className="site-menu quick1">
              <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item has-submenu">
                        <button type="button" className="btn " data-toggle="collapse" data-target="#demo1a">{t("Print Unpaid Demands")}<i className="bi bi-chevron-down" /></button>
                        <div id="demo1a" className="collapse quick-scrl"> 
                            <li className="nav-item">
                              <button type="button" className="btn" data-toggle="collapse"  data-target="#demo7">{t("Property Tax")}<i className="bi bi-chevron-down" /></button>
                                <div id="demo7" className="collapse"> 
                                      <li className="nav-item"><a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentAllUnpaidDemand.jsp" target='_blank'> {t("All Bill")} </a></li>
                                      <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/MiscellaneousSuspensePay.jsp'  target='_blank'> {t("Suspense")}</a></li>
                                      <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'  target='_blank'> {t("UAA Online Submission")} </a></li>
                                </div>
                            </li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp'  target='_blank'>{t("Building")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/KMCPortalLicensePaymentAction.do"  target='_blank'> {t("CE (Licence Dept.)")} </a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/SwmCeFeePayment.jsp"  target='_blank'> {t("SWM)")} </a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href="https://www.kmcgov.inKMCPortal/jsp/SorDemandPayment.jsp" target='_blank'> {t("Ch. Valuer & Surveyor")}</a></li>
                            <button type="button" className="btn " data-toggle="collapse"  data-target="#demo8">{t("Market")}<i className="bi bi-chevron-down" /></button>
                            <div id="demo8" className="collapse"> 
                            <li className="nav-item"><a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketPayment.jsp'> {t("Regular Demand")} </a></li>
                            <li className="nav-item"><a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketMiscPayDemand.jsp'> {t("Misclennious Demand")} </a></li>
                            </div>
                            <li className="nav-item"><Link className="nav-link fontSizeClass" to='/KMC_Advertisement'>{t("Advertisement")}</Link></li>
                            <button type="button" className="btn " data-toggle="collapse"  data-target="#demo9">{t("Amusement")}<i className="bi bi-chevron-down" /></button>
                              <div id="demo9" className="collapse"> 
                                  <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/KMCPortalAmusementPaymentAction.do' target='_blank'>{t("Amusement Fees (Renew/Casual/New)")}</a></li>
                                  <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target='_blank'>{t("Casual Demand Generation and payment")}</a></li>
                              </div>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCCarParkingPayment.jsp' target='_blank'>{t("Parking")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalWaterSupplyPayment.jsp' target='_blank'>{t("Water Supply")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp' target='_blank'>{t("Drainage")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/BusteePayment.jsp' target='_blank'>{t("Bustee")}</a></li>
                            <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HawkerDemandPay.jsp' target='_blank'>{t("Hawker")}</a></li>
                        </div>
                    </li>
                  </ul>
              </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='' target='_blank'> {t(" Dog Registration ")}  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Circular_21_10_2019.pdf' target='_blank'>  {t(" West Bengal Right to")} </a>
                </li>
              </ul>
            </nav>
          </div>

        </div>
        
        <div className="col-lg-4">
          
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Circular_21_10_2019.pdf' target='_blank'> {t("Public Service Act,2013  ")} </a>
                </li>
              </ul>
            </nav>
          </div>         
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target='_blank'> {t(" Application/Payment for Potable Water Analysis ")} </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/DrainageNetworkMap.jsp' target='_blank'>{t("Ward wise Drainage Network Map ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/TownVendingCommitteeList.jsp' target='_blank'>{t("Town Vending committee List ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <Link className="nav-link fontSizeClass" to='/Mom_tvc'> {t("MOM-TVC")} </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Budget_English_2024_2025.pdf' target='_blank'>{t("  Budget Statement 2024-2025 ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Budget_Estimate_2024_2025.pdf' target='_blank'>{t("Budget Estimates 2024-2025 ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/PCB_JanoBingapti_07_08_2023.pdf' target='_blank'>{t(" Public Notice of Pollution Control Board, West Bengal ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Advisory_Dengue_07_08_2023.pdf' target='_blank'>{t("  Advisory regarding prevention of Dengue ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/WARDWISE_BSK_KMC.pdf' target='_blank'>{t("  BSK CENTERS  ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/CD_WASTE_POLICY_01092023.pdf' target='_blank'>{t(" C&D WASTE POLICY ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Certificate_Climate_11_03_2024.pdf' target='_blank'>{t(" Notice inviting application for Certificate course on Climate Change and Sustainable Development ")}</a>
                </li>
              </ul>
            </nav>
          </div> 
                                  
        </div>

        <div className="col-lg-4">         
          
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Street_No_Vending_Zone_22_01_2024.pdf' target='_blank'>{t("  PROPOSED LIST OF STREETS UNDER DIFFERENT KMC WARDS TO BE RECOMMENDED AS NO VENDING ZONES  ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Street_No_Vending_Zone_22_01_2024.pdf' target='_blank'>{t("  PROPOSED LIST OF STREETS UNDER DIFFERENT KMC WARDS TO BE RECOMMENDED AS NO VENDING ZONES ")}</a>
                </li>
              </ul>
            </nav>
          </div> 
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/downloads/Slaughtering_Animals_08_06_2024.pdf' target='_blank'>{t(" Locations of Slaughtering of Animals on the Occasion of EID-UL-ZOHA (Bakri-EID) on 17.06.2024,18.06.2024 and 19.06.2024.   ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick2">
              <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion1">
                    <li className="nav-item has-submenu">
                      <button type="button" className="btn " data-toggle="collapse" data-target="#demo1">{t("Check Hearing Notice")}<i className="bi bi-chevron-down" /></button>
                      <div id="demo1" className="collapse"> 
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNoticeSearch.jsp' target='_blank'>{t("Property Tax")}</a></li>
                        <li className="nav-item"><a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/HearingNotice.jsp' target='_blank'>{t("Building")}</a></li>
                      </div>
                    </li>
                  </ul>
              </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/NEFTPaymentInfo.jsp' target='_blank'>{t("NEFT Payment Information")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' target='_blank'>{t(" Register Your KYC Through      External User Login ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBirthRecordSearch.jsp' target='_blank'>{t(" Find Birth Records ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/KMCBirthRecordSearch.jsp' target='_blank'>{t(" Find Birth Records ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/NocDetails.jsp' target='_blank'>{t(" Download NOC (A.C.) ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/MutationStatusSearch.jsp' target='_blank'>{t(" Status of Mutation Application ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="site-menu quick1">
            <nav className="sidebar card">
              <ul className="nav flex-column" id="nav_accordion">
                <li>
                <a className="nav-link fontSizeClass" href='https://www.kmcgov.in/KMCPortal/jsp/PlanProposalStatusSearch.jsp' target='_blank'>{t("  Building Plan Proposal Status ")}</a>
                </li>
              </ul>
            </nav>
          </div>
          
        </div>

      </div>
    </div>
  </section>
</>

</>

  )
}

export default Quick_Links