import React from "react";
import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Home = () => {
  const fileDownload = (fileStoreId) => {

    let url = `https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
    axios
      .post(url, { "fileId": fileStoreId, "userId": "392" })
      .then((res) => {
        console.log("response is --- ", res);

        if (res && res.data && res.status === 200) {
          const base64 = res.data[0] && res.data[0].base64_data;
          const pdfData = 'data:application/pdf;base64,' + base64;

          let html = '<html>' +
            '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
            '<body>' +
            '<iframe src="' + pdfData + '"></iframe>' +
            '</body></html>';
          console.log(html);
          let a = window.open();
          a.document.write(html);
        }
      })
      .catch((e) => {
        // setLoader(false);
        console.log("The error is ", e);
      })
  }
  // AOS.init({
  //   duration: 2000, // Set the duration to 1000 milliseconds (1 second) or adjust as needed
  //   // Other AOS configuration options here
  // });
  const increaseFontSizeBy1px = () => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style);
      el[i].style.fontSize = (fontSize + 1) + 'px';
    }
  };
  const decreaseFontSizeBy1px = () => {
    var el = document.getElementsByClassName('fontSizeClass');
    for (let i = 0; i < el.length; i++) {
      var style = window.getComputedStyle(el[i], null).getPropertyValue('font-size');
      var fontSize = parseFloat(style);
      el[i].style.fontSize = (fontSize - 1) + 'px';
    }
  };

  const [ProjectService, setProjectService] = React.useState(false);
  const [SexualService, setSexualService] = React.useState(false);
  const [DocumentService, setDocumentService] = React.useState(false);
  const [PropertyTax, setPropertyTax] = React.useState(false);
  const [Market, setMarket] = React.useState(false);
  const [AmusementService, setAmusementService] = React.useState(false);

  const PropertyTaxToggle = () => {
    setPropertyTax(!PropertyTax);
  };
  const MarketToggle = () => {
    setMarket(!Market);
  };
  const AmusementServiceToggle = () => {
    setAmusementService(!AmusementService);
  };
  const ProjectServiceToggle = () => {
    setProjectService(!ProjectService);
  };
  const SexualServiceToggle = () => {
    setSexualService(!SexualService);
  };
  const DocumentServiceToggle = () => {
    setDocumentService(!DocumentService);
  };
  const { t, i18n, ready } = useTranslation();
  return (
    <>
      <Banner />
      <div className="help">
        <a className="fontSizeClass">
          <img src="assets/img/photo-4.png" alt="alt-image" className="img-fluid img" />
        </a>
      </div>

      <section className="scrool-message">
        <div className="container">
          <div className="span12 scroolMessage d-flex" >
            <span className='fontSizeClass '>
              {t("home_banner_sliding_text")} <strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text1")} <strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text2")} <strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text3")}<strong>***</strong>&nbsp;
              {t("home_banner_sliding_text4")}<strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text5")}<strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text6")}<strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text7")}<strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text8")}<strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text9")} <strong> *** </strong>&nbs;
              {t("home_banner_sliding_text10")}<strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text11")} <strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text12")}<strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text13")} <strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text14")}<strong>***</strong>&nbsp;
              {t("home_banner_sliding_text15")}<strong>***</strong>&nbsp;
              {t("home_banner_sliding_text16")}<strong> ***</strong>&nbsp;
              {t("home_banner_sliding_text17")} <strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text18")} <strong> *** </strong>&nbsp;
              {t("home_banner_sliding_text19")} <strong> *** </strong>
            </span>
          </div>
        </div>
      </section>

      <section id="clients" className="clients">
        <div className="social-media wow slideInDown" style={{ visibility: "visible", animationName: "slideInDown" }}>
          <div className="facebook" rel="ebInfo" title="facebook">
            <a href="https://www.facebook.com/KolkataMunicipalCorporation.official" target="_blank">
              <img src="assets/img/face-book-1.png" alt="alt-image" />
            </a>
          </div>
          <div className="twitter" rel="ebInfo" title="Twitter">
            <a href="https://twitter.com/kmc_kolkata" target="_blank">
              <img src="assets/img/twitter1.png" alt="alt-image" />
            </a>
          </div>
          <div className="support" rel="ebInfo" title="KMC Support">
            <a href="https://www.kmcgov.in/KMCPortal/jsp/ContactDept.jsp" target="_blank">
              <img src="assets/img/support.png" alt="alt-image" />
            </a>
          </div>
          <div className="whatsapp" rel="ebInfo" title="Whatsapp">
            <Link to="/Wathsapp" target="_blank">
              <img src="assets/img/social-media.png" alt="alt-image" />
            </Link>
          </div>
        </div>
        <div className="container aos-init aos-animate" data-aos="zoom-out">
          <div className="section-main-heading mb-4">
            <p className="main-heading-title">
              <span className="main-heading-border fontSizeClass">{t("top_bar_title")}</span>
            </p>
          </div>
          <div className="menu-slider">
            <div className="menu-slide-track">

              <div className="menu-slide">
                <div className="service-item ">
                  <div className="img">
                    <img src="assets/img/imp-pic/Malaria Clinics.jpg"
                      className="img-fluid" alt="image" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">

                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Health_Malaria_CLinics" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text2")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/imp-pic/Maternity-Homes.jpg"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Health_Maternity_Homes" className="stretched-link">
                      <h3 className='fontSizeClass'>

                        {t("home_sliding_logo_text3")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img src="assets/img/imp-pic/KMC_monitor_water_wastage_3.jpg" className="img-fluid" alt="image" />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/WaterStations" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text4")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/Banner/Members-Mayor-In-Council-1.jpg"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Overview" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text5")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/imp-pic/Borough Committee.jpg"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Borough_Committee" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text6")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/Banner/Councillors.jfif"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Councillors" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text7")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/imp-pic/chest-clinic.PNG"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Health_Chest_Clinincs" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text8")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/Banner/Dispensaries.jpg"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Health_KMC_Dispensaries" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text9")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/imp-pic/Birth Registration Centers.jpg"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Birth_Ragistration_center" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text10")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/Banner/Crematoriums.png"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/KMCCrematorium" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text11")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/imp-pic/Drainage Pumping Stations.jpg"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/Drainage_Drainage_Pumping_station" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text12")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="menu-slide">
                <div className="service-item">
                  <div className="img">
                    <img
                      src="assets/img/Banner/e_kolkata_Citizen_Service_6.jpg"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="details position-relative">
                    <div className="icon">
                      <i className="bi bi-gear" />
                    </div>
                    <Link to="/EKolkataCenter" className="stretched-link">
                      <h3 className='fontSizeClass'>
                        {t("home_sliding_logo_text13")}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 site-menu-div">
              <div className="site-menu  ">
                <nav className="sidebar card py-2">
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
              <Link className="nav-link fontSizeClass" to="/KMC_Department">  {t("KMC_Departments")} <i class="bi bi-chevron-right"></i> </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/MunicipalHistory">
                        {t("Municipal History")}
                      </Link>
                    </li>
                    
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/About_Kolkata">
                        {t("About_Kolkata")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Dashboard">
                        {t("EODB_Dashboard_Online_Services")}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Auction">
                        {t("eAuction")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Citizen_charter_Home">
                        {t("Citizen Charter")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Download_Form">
                        {t("Downloadable_Forms")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/E_News_Letter">
                        {t("Publications")}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <button type="button" className="btn " data-toggle="collapse" data-target="#demo">{t("Project")} <i className="bi bi-chevron-down" /></button>
                      <div id="demo" className="collapse">    
                      <Link className="nav-link fontSizeClass" to="/CBP_Home">
                        {t("1. CBP")}
                      </Link>

                      <a className="nav-link fontSizeClass" target={"_blank"} href="https://www.s-parking.com/KMC/">2. MPLAD / BEUP </a>
                      <Link className="nav-link fontSizeClass" to="/Project_Faqs">  {t("3. Faqs")} </Link>

                      </div>
                    </li>
                    
                    <li className="nav-item">
                    <Link className="nav-link fontSizeClass" to="/Procedure">
                        {t("Grievance_Redressal")}
                      </Link>
                    </li>

                    <li className="nav-item">
                    <a href="https://kmc.wb.gov.in/citizen/language-selection" className="nav-link fontSizeClass" >  Grievance KMC 2.0 </a>
                    </li>

                    <li className="nav-item">
                      <button type="button" className="btn " data-toggle="collapse" data-target="#Notification">{t(" HR System")} <i className="bi bi-chevron-down" /></button>
                      <div id="Notification" className="collapse">
                       <a className="nav-link fontSizeClass" target={"_blank"} href="https://www.kmcgov.in/KMCPortal/downloads/NOTIFICATION_26_07_2023.pdf">  Notification </a>
                       <Link className="nav-link fontSizeClass" to="/Chief_Manager_Circular"> {t("Office Order/Circular")} </Link>  
                      </div>
                    </li>
                    
                    <li className="nav-item">
                      <a className="nav-link fontSizeClass" onClick={(e) => fileDownload('868')} >{t("List of Pay & Use Toilets")}</a>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/RTI_List_of_1st_Appellate_Authorities">
                        {t("RTI")}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <button type="button" className="btn " data-toggle="collapse" data-target="#Sexual_Harassment_Redressal_Cell">{t("Sexual_Harassment_Redressal_Cell")} <i className="bi bi-chevron-down" /></button>
                      <div id="Sexual_Harassment_Redressal_Cell" className="collapse">
                        <Link className="nav-link fontSizeClass" onClick={(e) => fileDownload('1948')}>1. {t("Important Points for making Complaints")} </Link>
                        <Link className="nav-link fontSizeClass" onClick={(e) => fileDownload('1949')}>2. {t("Sexual Harassment of Women at Work place(Prevention,Prohibition and Redressal)Act,2013")}</Link>
                        <Link className="nav-link fontSizeClass" onClick={(e) => fileDownload('1950')}>3. {t("Ch.Manager(P)'s Circular No.20/SHC of 2018-19 dated 19.06.2018")} </Link>
                        <Link className="nav-link fontSizeClass" onClick={(e) => fileDownload('47')}> 4. {t("Chief Manager(Personal)'s Circular No.26/SHRC of 2021-22 dated 27.07.2021")} </Link>
                        <a className="nav-link fontSizeClass" target={"_blank"} href="https://www.kmcgov.in/KMCPortal/downloads/CM_Circular19_07_08_2024.pdf">5. Chief Manager(Personal)'s Circular No.19/SHRC of 2024-25 dated 06.08.2024 </a>                   
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/KMC_Recruitments">
                        {t("Recruitment / Result/Training")}
                      </Link>
                    </li>
                    <li className="nav-item" >
                      <button type="button" className="btn " data-toggle="collapse" data-target="#Document_Authentication">{t("Document Authentication")} <i className="bi bi-chevron-down" /></button>
                      <div id="Document_Authentication" className="collapse">
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/PDReceipt.jsp" target="_blank">1. {t("Assessment PD/FS")} </a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LOIReceipt.jsp" target="_blank">2.  {t("Assessment LOI")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/NocDetails.jsp" target="_blank">3. {t("Assessment NOC")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/SuspenseReceipt.jsp" target="_blank">4. {t("Assessment Suspense")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/LicenseReceipt.jsp" target="_blank">5. {t("License(CE)")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/BuildingReceipt.jsp" target="_blank">6. {t("Building")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/BirthRecordSearch.jsp" target="_blank">7. {t("Birth Certificate")}</a>
                        <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/DeathRecordSearch.jsp" target="_blank">8. {t("Death Certificate")}</a>
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link fontSizeClass" to="/Municipal_secretary_circular">
                        {t("Circulars")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link fontSizeClass" href="https://www.kmcgov.in/KMCPortal/jsp/DocumentDetails.jsp" target="_blank">
                        {t("M.I.C Agenda & Resolution")}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 message-board">
              <div className="row">
                <div className="swap-div">
                  {/* <div className="col-lg-7 col-md-7 col-sm-6 text-center aos-init aos-animate message_board-card" data-aos="fade-up" data-aos-delay="10" > */}
                  <div className="col-lg-7 col-md-7 col-sm-6 text-center aos-animate message_board-card vertical-animation" data-aos="fade-up">
                    <div className="whats-new">
                      <div className="section-title mes-title">
                        <h4 className="fontSizeClass">{t("Message_board")}</h4>
                      </div>
                      <div className="body1-left-side title3">
                        <div className="block-hdnews">
                          <div
                            className="list-wrpaaer"
                            style={{ position: "relative", overflow: "hidden" }}  >
                            <ul className="list-aggregate marquee-vertical">
							
							                {/* <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> OCT </h4> <span className="fontSizeClass"> 5.10.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="" target={"_blank"}>
                                    {t("")}</a>
                                </div> 
                                </li> */}

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Mar </h4> <span className="fontSizeClass">22.03.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Specialist_MO_15_03_2024.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 22.03.2024, for the post of  Specialist Medical Officer (G&amp;O) on contractual basis under the Health Department  of Kolkata Municipal Corporation.  Adv. No . H/010/KMC/2023–24. dated 14.03.2024")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Mar </h4> <span className="fontSizeClass"> 14.03.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Polyclinc_14_03_2024.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Specialist Medical Officer for Polyclinic under XV Finance Commission and for Khiddrpore (U-CHC) under Kolkata City NUHM Society")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Mar </h4> <span className="fontSizeClass"> 09.03.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_09_03_2024.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Medical Officer (Part time)  for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 11/Kolkata City NUHM Society/2023 – 24. dated  27.01.2024")}</a>
                                </div>
                              </li>

							  
							                <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Feb </h4> <span className="fontSizeClass"> 12.02.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Assistant_Analyst_12_02_2024.pdf" target={"_blank"}>
                                    {t("Engagement of 4 nos. Assistant Analyst and 3 nos. Lab Assistant on contractual basis  for Central Lab (Food) under the Health Department  of Kolkata Municipal Corporation.  Adv. No . H/09/KMC/2023–24. dated 09.12.2023")}</a>
                                </div>
                              </li>
							  
							                <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Feb </h4> <span className="fontSizeClass"> 12.02.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Nursing_Incharg_12_02_2024.pdf" target={"_blank"}>
                                    {t("Engagement of  1no. Nursing –in-charge for Khidderpore (U-CHC) under Kolkata City NUHM Society on contractual basis, Adv. No . 14/Kolkata City NUHM Society/2023 – 24. dated 24.11.2023")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Feb </h4> <span className="fontSizeClass"> 12.02.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Khidderpore_12_02_2024.pdf" target={"_blank"}>
                                    {t("Engagement of  1no. Medical officer –in-charge for Khidderpore (U-CHC) under Kolkata City NUHM Society on contractual basis,  Adv. No . 13/Kolkata City NUHM Society/2023 – 24. dated 24.11.2023")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Feb </h4> <span className="fontSizeClass"> 12.02.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Specialist_NUHM_12_02_2024.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 27.02.2024, for the post of  Specialist ( Medicine and Pathologist) for U-CHC  Khidderpore  under Kolkata City NUHM Society.  Adv. No . 09/Kolkata City NUHM Society/2023–24. dated 07.12.2023")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Feb </h4> <span className="fontSizeClass"> 12.02.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Specialist_Polyclinic_12_02_2024.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 23.02.2024, for the post of Specialist (Medicine, Ophthalmologist, Cardiology, ENT, Endocrinology and Orthopaedics) on contractual basis under XV FC under the Health Department of Kolkata Municipal Corporation.  Adv. No . H/08/KMC/2023–24. dated 10.01.2024")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Feb </h4> <span className="fontSizeClass"> 12.02.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_12_02_2024.pdf" target={"_blank"}>
                                    {t("Walk-in Interview held on 20.02.2024, 75nos. Medical officer (part time) contractual basis under the Kolkata City NUHM Society. Adv. No . 11/Kolkata City NUHM Society/2023 – 24. dated 27.01.2024")} </a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Feb </h4> <span className="fontSizeClass"> 05.02.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/HHW_05_02_2024.pdf" target={"_blank"}>
                                    {t("Engagement of 118 nos. Honorary Health Workers (HHW) under Health Department of KMC vide Advt. No. H/07/KMC/2023-24. dated 22.11.2023")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Feb </h4> <span className="fontSizeClass"> 05.02.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_05_02_2024.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Medical Officer (Part time)  for  urban primary health centers in Kolkata City NUHM Society areas on contractual basis against Adv. No - 10/Kolkata City NUHM Society/2023 – 24. dated 07.12.2023")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Jan </h4> <span className="fontSizeClass"> 22.01.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Specialist_MO_Polyclinic_22_01_2024.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Specialist Medical Officer  for Polyclinic  under Health Department KMC on contractual basis against  on contractual basis against Adv. No . H/06/KMC/2023 – 24. dated  07.12.2023")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Jan </h4> <span className="fontSizeClass"> 22.01.2024 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Specialist_MO_NUHM_22_01_2024.pdf" target={"_blank"}>
                                    {t(" List of selected candidate for the post of Specialist Medical Officer  for Khiddrpore (U-CHC) under Kolkata City NUHM Society  Adv. No . 09/Kolkata City NUHM Society/2023 – 24. dated  07.12.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Jan </h4> <span className="fontSizeClass"> 18.01.2024 </span>
                                </div>
                                <div className="buy-policy-2"> <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Cancellation_Special_Officer_Building_18_01_2024.pdf" target={"_blank"}>
                                    {t(" Notice for cancellation of Advertisement for appointment to the Special Officer(Building) ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Jan </h4> <span className="fontSizeClass"> 04.01.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_04_01_2023.pdf" target={"_blank"}>
                                    {t(" Walk-in Interview held on 09.01.2024, 68nos. Medical Officer (part time) contractual basis under the Kolkata City NUHM Society  against Adv. No . 10/Kolkata City NUHM Society/2023 – 24. dated 07.12.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 28.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Requitement_28122023.pdf" target={"_blank"}>
                                    {t(" Recruitment Regulations for the posts Sub-Registrar under Health Department, KMC. dated  28.12.2022 ")}</a>
                                </div>
                              </li>
							  
							  
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 20.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_2nd_XV_FC.pdf" target={"_blank"}>
                                    {t(" 2nd List and 6th List of selected candidates for the post of Medical Officer  under XV Finance Commission under Health Department  of KMC on contractual basis against Adv. No H/03/KMC/2023 – 24. dated  07.08.2023 and Adv. No H/01/KMC/2022 – 23. dated  20.10.2022 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 20.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Staff_Nurse_2nd_XV_FC.pdf" target={"_blank"}>
                                    {t(" 2nd List of selected candidates for the post of  Staff  Nurse  for Polyclinics and Urban Health and Wellness Centers under XV Finance Commission under Health Department of KMC with respect to  Adv. No . H/05/KMC/2022-23 dt. 20.12.2022. and Adv. No . H/06/KMC/2022-23 dt. 20.12.2022. ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 16.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Specialist_Polyclinic_16_12_2023.pdf" target={"_blank"}>
                                    {t(" Walk-in Interview on 22.12.2023, for the post of  Specialist (G&O, Medicine, Paediatrics, Ophthalmologist, Cardiology, ENT, Endocrinology and Orthopaedics) on contractual basis under XV FC of Kolkata Municipal Corporation.  Adv. No . H/06/KMC/2023–24. dated 07.12.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 16.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Specialist_NUHM_16_12_2023.pdf" target={"_blank"}>
                                    {t(" Walk-in Interview on 21.12.2023, for the post of  Specialist ( Medicine,Pathologist and Paediatrics,) for U-CHC at Khidderpore  under Kolkata City NUHM Society.  Adv. No . 09/Kolkata City NUHM Society/2023–24. dated 07.12.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 15.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_15_12_2023.pdf" target={"_blank"}>
                                    {t(" List of selected candidates for the post of Medical Officer (Part time)  for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 08/Kolkata City NUHM Society/2023 – 24. dated  07.10.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 14.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/NUHM_Staff_Nurse_14_12_2023.pdf" target={"_blank"}>
                                    {t(" 6th list of selected candidates for the post of  Staff  Nurse (contractual)  for  urban primary health centers in Kolkata City NUHM Society areas against Adv. No . 04/Kolkata City NUHM Society/2022 – 23. dated 06.09.2022 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 14.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/NOTIFICATION_advertisements_14_12_2023.pdf" target={"_blank"}>
                                    {t(" NOTIFICATION for fixing up QR codes on advertisements ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 08.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Pharmacist_NUHM_08_12_2023.pdf" target={"_blank"}>
                                    {t(" Engagement of 19 nos. Pharmacist (contractual) for Urban Primary Health Centers under Kolkata City NUHM Society. Advt. No. 08/Kolkata City NUHM Society/2023 – 24, dated: 25.11.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 08.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_Polyclinic_08_12_2023.pdf" target={"_blank"}>
                                    {t(" Engagement of 07 nos. Staff Nurse for Polyclinic on contractual basis under XV Finance Commission.  Advt. No. H/05/KMC/2023-24. dated 13.10.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 08.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_UHWC_08_12_2023.pdf" target={"_blank"}>
                                    {t(" Engagement of 29 nos. Staff Nurse for UHWC on contractual basis under XV Finance Commission.  Advt. No. H/04/KMC/2023-24. dated 13.10.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Dec </h4> <span className="fontSizeClass"> 02.12.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href=" https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_02_12_2023.pdf" target={"_blank"}>
                                    {t(" 6th list of selected candidates for the post of Medical Officer for  urban primary health centers in Kolkata City NUHM Society areas on contractual basis against Adv. No - 13/Kolkata City NUHM Society/2022 – 23. dated 28.01.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> NOV </h4> <span className="fontSizeClass">06.11.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_06_11_2023.pdf" target={"_blank"}>
                                    {t(" Walk-in Interview on 22.11.2023, 24nos. Medical officer (part time) on contractual basis under the Kolkata City NUHM Society ,  Adv. No . 08/Kolkata City NUHM Society/2023 – 24. dated 07.10.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> OCT </h4> <span className="fontSizeClass"> 31.10.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/ADVERTISING_AGENCIES_31_10_2023.pdf" target={"_blank"}>
                                    {t(" Notice to all Advertisers & Advertising Agencies regarding temporary flexes, banner, cut-outs etc. put up during the Durga Puja, 2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> OCT </h4> <span className="fontSizeClass"> 16.10.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Polyclinics_Staff_Nurse_16_10_2023.pdf" target={"_blank"}>
                                    {t(" List of selected candidates for the post of  Staff  Nurse  for Polyclinics under XV Finance Commission on contractual basis, Health Department, KMC with respect to  Adv. No . H/6/KMC/2022-23 dt. 20.12.20223 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> OCT </h4> <span className="fontSizeClass"> 16.10.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/UHWC_Staff_Nurse_16_10_2023.pdf" target={"_blank"}>
                                    {t(" List of selected candidates for the post of  Staff  Nurse  for Urban Health and Wellness Centres under XV Finance Commission on contractual basis, Health Department, KMC with respect to  Adv. No . H/05/KMC/2022-23 dt. 20.12.2022 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass"> 21.08.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Health_Department_21_08_2023.pdf" target={"_blank"}>
                                    {t(" List of selected candidates for the post of Medical Officer (Part time)  for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 07/Kolkata City NUHM Society/2023 – 24. dated  21.08.2023 ")}</a>
                                </div>
                              </li>
							  
							  <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Jan </h4> <span className="fontSizeClass"> 28.01.2023 </span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Health_Department_28_01_2023.pdf" target={"_blank"}>
                                    {t("5th list of selected candidates for the post of  Medical Officer  for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 13/Kolkata City NUHM Society/2022 – 23. Dated  28.01.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Sep </h4> <span className="fontSizeClass">06.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Health_Department_06_09_2023.pdf" target={"_blank"}>
                                    {t("5th list of selected candidates for the post of  Staff  Nurse (contractual)  for  urban primary health centers  in Kolkata City NUHM Society areas  against Adv. No . 04/Kolkata City NUHM Society/2022 – 23. Dated 06.09.2022")} </a>
                                </div>
                              </li>
							  
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Sep </h4> <span className="fontSizeClass">30.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Continuation_30_09_2023.pdf" target={"_blank"}>
                                    {t("Continuation of work by the existing Licensees at the existing Licence Fee at the respective lots being allotted to them except the lots being allotted to the successful three H1 bidders in the e-tender NIT No. KMC/CP/MAIN/e-TENDER (01)/2023-24")}</a>
                                </div>
                              </li>
                              
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Sep </h4> <span className="fontSizeClass">27.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Health_Department_27_09_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Medical Officer (contractual) under XV Finance Commission under Health Department of KMC. Ref: Adv. No H/03/KMC/2023 – 24. dated 07.08.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Sep </h4> <span className="fontSizeClass">22.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_NUHM_22_09_2023.pdf" target={"_blank"}>
                                    {t("4th list of selected candidates for the post of Staff  Nurse (contractual)  for urban primary health centers in Kolkata City NUHM Society areas against Adv. No . 04/Kolkata City NUHM Society/2022 – 23. Dated 06.09.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Sep </h4> <span className="fontSizeClass">20.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NHUM_20_09_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Specialist Medical Officer  for Polyclinic  under Health Department KMC on contractual basis Ref: Adv. No . H/02/KMC/2023 – 24. dated  15.07.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> sep </h4> <span className="fontSizeClass">12.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_14_09_2023.pdf" target={"_blank"}>
                                    {t("4th list of selected candidates for the post of Medical Officer for urban primary health centers in Kolkata City NUHM Society areas on contractual basis against Adv. No - 13/Kolkata City NUHM Society/2022 – 23. dated 28.01.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Sep </h4> <span className="fontSizeClass">12.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_12_09_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview held on 22.09.2023, 29nos. of Medical officer (part time) contractual basis under the Kolkata City NUHM Society, Adv. No . 07/Kolkata City NUHM Society/2023 – 24. dated 21.08.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> sep </h4> <span className="fontSizeClass">05.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_NUHM_05_09_2023.pdf" target={"_blank"}>
                                    {t("2nd list of selected candidates for the post of Staff Nurse for  Khidderpore U-CHC, under Kolkata City NUHM Society on contractual basis against Adv. No . 10 /Kolkata City NUHM Society/2022 – 23. Dated 12.11.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> sep </h4> <span className="fontSizeClass">05.09.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_UrbanHealth_05_09_2023.pdf" target={"_blank"}>
                                    {t("5th list of selected candidates for the post of Medical Officer  for Urban Health & Wellness Centers under XV Finance Commission Health - Grant under Health Department of Kolkata Municipal Corporation Ref: Adv. No H/01/KMC/2022 – 23. Dated  20.10.202")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">26.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Staff_Nurse_NUHM_26_08_2023.pdf" target={"_blank"}>
                                    {t("3rd  list of selected candidates for the post of  Staff  Nurse for  urban primary health centers  under Kolkata City NUHM Society on contractual basis Ref: Adv. No . 04/Kolkata City NUHM Society/2022 – 23. Dated 06.09.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">24.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_24_08_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Medical Officer (Part time)  for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 04/Kolkata City NUHM Society/2023 – 24. dated  15.08.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">18.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_18_08_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 29.08.2023 26nos. of Medical Officer on contractual basis for Urban Health & Wellness Centre under the Health Department of Kolkata Municipal Corporation Advertisement No . H/03/KMC/2023-24 dated. 07.08.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">16.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Accountant_NUHM_16_08_2023.pdf" target={"_blank"}>
                                    {t("2nd list of selected candidate for the post of Medical Officer  for  Khidderpore U-CHC under Kolkata City NUHM Society on contractual basis against Adv. No - 3/Kolkata City NUHM Society/2023 – 24. Dated  19.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">07.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_07_08_2023.pdf" target={"_blank"}>
                                    {t("2nd list of selected candidate for the post of Medical Officer  for  Khidderpore U-CHC under Kolkata City NUHM Society on contractual basis against Adv. No - 3/Kolkata City NUHM Society/2023 – 24. Dated  19.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">07.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_07_08_2023.pdf" target={"_blank"}>
                                    {t("2nd list of selected candidate for the post of Medical Officer  for  Khidderpore U-CHC under Kolkata City NUHM Society on contractual basis against Adv. No - 3/Kolkata City NUHM Society/2023 – 24. Dated  19.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">04.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Contractual_Appointment_04_08_2023.pdf" target={"_blank"}>
                                    {t("Contractual Appointment")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">03.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_Health_03_08_2023.pdf" target={"_blank"}>
                                    {t("Interview on 11.08.2023,16.08.2023, 18.08.2023, 21.08.2023  to  23.08.2023, against Adv. No . No. H/05/KMC/2022-23 dated 20.10.2022. for  the post of Staff  Nurse (contractual) for  Urban Health and Wellness Centres under the XV th Finance Commission Health Grant under the Health Department of Kolkata Municipal Corporation")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">03.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_Polyclinic_03_08_2023.pdf" target={"_blank"}>
                                    {t("Interview on 07.08.2023 & 09.08.2023, against Adv. No . No. H/06/KMC/2022-23 dated 20.10.2022. for the post of Staff  Nurse (contractual) for  Polyclinic under the XV th Finance Commission Health Grant under the Health Department of Kolkata Municipal Corporation")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">02.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Laboratory_Technicians_02_08_2023.pdf" target={"_blank"}>
                                    {t("2nd list of selected candidate for the post of Laboratory Technician  for  Khidderpore U-CHC under Kolkata City NUHM Society on contractual basis against Adv. No - 8/Kolkata City NUHM Society/2022 – 23. Dated  12.11.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">02.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Laboratory_Technicians_02_08_2023.pdf" target={"_blank"}>
                                    {t("2nd list of selected candidate for the post of Laboratory Technician  for  Khidderpore U-CHC under Kolkata City NUHM Society on contractual basis against Adv. No - 8/Kolkata City NUHM Society/2022 – 23. Dated  12.11.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">02.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Pharmacist_NUHM_02_08_2023.pdf" target={"_blank"}>
                                    {t("3rd  List of selected candidates for the post of  Pharmacist for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against  Adv. No . 01/Kolkata City NUHM Society/2022 – 23. dated 05.04.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">01.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Specialist_01_08_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 19.08.2023, for the post of  Specialist (G&O, Medicine, Paediatrics, Ophthalmologist, Cardiology, ENT, Endocrinology and Orthopaedics) on contractual basis under the Health Department  of Kolkata Municipal Corporation against Adv. No . H/02/KMC/2023–24. dated 15.07.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">01.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Specialist_01_08_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 19.08.2023, for the post of  Specialist (G&O, Medicine, Paediatrics, Ophthalmologist, Cardiology, ENT, Endocrinology and Orthopaedics) on contractual basis under the Health Department  of Kolkata Municipal Corporation against Adv. No . H/02/KMC/2023–24. dated 15.07.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass"> Aug </h4> <span className="fontSizeClass">01.08.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Specialist_01_08_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 19.08.2023, for the post of  Specialist (G&O, Medicine, Paediatrics, Ophthalmologist, Cardiology, ENT, Endocrinology and Orthopaedics) on contractual basis under the Health Department  of Kolkata Municipal Corporation against Adv. No . H/02/KMC/2023–24. dated 15.07.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">28.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Result_KDTCS_28_07_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for difference post, against Adv. No – KDTCS/Office/37/23 dated 03.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">28.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Result_KDTCS_28_07_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for difference post, against Adv. No – KDTCS/Office/37/23 dated 03.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">25.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_25_07_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Medical Officer &  Specialist Medical Officer  for Khiddrpore (U-CHC) under Kolkata City NUHM Society on contractual basis against Adv. No . 03/Kolkata City NUHM Society/2023 – 24. dated  19.06.2023 and 02/Kolkata City NUHM Society/2023 – 24. dated  06.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">24.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Laboratory_Technicians_24_07_2023.pdf" target={"_blank"}>
                                    {t("Engagement of  8 nos.  Laboratory Technicians on contractual basis under the Kolkata City NUHM Society  against Adv. No . 05/Kolkata City NUHM Society/2023–24. dated 15.07.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">19.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_19_07_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 04.08.2023, 59nos. Medical officer (part time) contractual basis under the Kolkata City NUHM Society  against Adv. No . 04/Kolkata City NUHM Society/2023 – 24. dated 15.07.2023 ")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">19.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Pharmacist_NUHM_19_07_2023.pdf" target={"_blank"}>
                                    {t("2nd List of selected candidates for the post of Pharmacist for its urban primary health centers in Kolkata City NUHM Society areas on contractual basis against Adv. No. 01/Kolkata City NUHM Society/2022 – 23 ")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">14.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Quality_Manager_14_07_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidate for the post of Quality Manager   on contractual basis against Adv. No - 12/Kolkata City NUHM Society/2022 – 23. dated  15.12.2022 ")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">11.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Food_Analyst_11_07_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidate for the post of Analyst for Modified Food Safety on Wheels on Contractual Basis, Under Health Department, Kolkata Municipal Corporation against Adv. No H/04/KMC/2022-23 dt. 23.02.2023 ")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">11.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Community_Health_Assistants_11_07_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of  Community Health Assistants (Urban) under XV Finance Commission Health Grant,Health Department of Kolkata Municipal Corporation on contractual basis against Adv. No. H/07/KMC/2022–23. Dated 20.10.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">01.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Specialist_MO_10_07_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Specialist Medical Officer  for Polyclinic  under Health Department KMC on contractual basis against  on contractual basis against Ref. Adv. No . H/01/KMC/2023 – 24. dated 06.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">01.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_urban_01_07_2023.pdf" target={"_blank"}>
                                    {t("3rd list of selected candidates for the post of Medical Officer  for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 13/Kolkata City NUHM Society/2022 – 23. Dated  28.01.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">01.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_01_07_2023.pdf" target={"_blank"}>
                                    {t("4th list of selected candidates for the post of Medical Officer  for  Khidderpore U-CHC under Kolkata City NUHM Society on contractual basis against Adv. No - 7/Kolkata City NUHM Society/2022 – 23. Dated  12.11.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jul</h4> <span className="fontSizeClass">01.07.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_01_07_2023.pdf" target={"_blank"}>
                                    {t("4th list of selected candidates for the post of Medical Officer  under Health Department KMC on contractual basis against Adv. No H/01/KMC/2022 – 23. Dated  20.10.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">28.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_28_06_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 06.07.2023, 04 nos. Medical Officer on contractual basis for Kidderpore U-CHC under Kolkata City NUHM Society against Adv. No . 03/Kolkata City NUHM Society/2023–24. dated 19.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">27.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Laboratory_Technician_27_06_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the Computer Test and Interview post of Laboratory Technician, STS, STLS and TBHV against Memo. No. KDTCS/Office/37/23. dated 03.06.2023.")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">23.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/BakriEid_23_06_2023.pdf" target={"_blank"}>
                                    {t("LOCATIONS OF SLAUGHTERING OF ANIMALS ON THE OCCASION OF ID-UD-ZOHA (BAKRI-EID) ON 29/06/2023, 30/06/2023 AND 01/07/2023.")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">14.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_Rusult_14_06_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of Medical Officer (Part time)  for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 01/Kolkata City NUHM Society/2023 – 24. Dated  12.04.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">13.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Medical_Officer_13_06_2023.pdf" target={"_blank"}>
                                    {t("3rd list of selected candidates for the post of Medical Officer  under Health Department KMC on contractual basis against Adv. No H/01/KMC/2022 – 23. Dated  20.10.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">09.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Computer_Test_NUHM_09_06_2023.pdf" target={"_blank"}>
                                    {t("The Computer Test  & Interview for the post of Quality Manager against Adv. No . 12/Kolkata City NUHM Society/2022 – 23. dated  15.12.2022 under Kolkata City NUHM Society")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">07.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Specialist_MO_NUHM_07_06_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 12.06.2023, 05 nos. Specialist (Surgeon, Orthopedics, Medicine and Anesthetist) on contractual basis for Kidderpore U-CHC under Kolkata City NUHM Society against Adv. No . 02/Kolkata City NUHM Society/2023–24. dated 06.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">06.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Interview_06_06_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 12.06.2023, 90 nos. Specialist (G&O, Medicine, Paediatrics, Ophthalmologist, Cardiology, ENT, Endocrinology and Orthopendics) on contractual basis under the Health Department  of Kolkata Municipal Corporation against Adv. No . H/01/KMC/2023–24. dated 31.05.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">05.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Engagement_NTEP_05_06_2023.pdf" target={"_blank"}>
                                    {t("Engagement of  STS, STLS, LT, TBHV and MO(NTEP) under National Tuberculosis Elimination Programme on contractual basis against Adv. No . KDTCS/Office/37/23. dated 03.06.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jun</h4> <span className="fontSizeClass">03.06.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Pharmacist_NUHM_03_06_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the post of  Laboratory Technician, Pharmacist, Staff Nurse & Ophthalmic Assistant for  Khidderpore U-CHC, under Kolkata City NUHM Society on contractual basis against Adv. No . 08,9,10 & 11/Kolkata City NUHM Society/2022 – 23. Dated 12.11.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">May</h4> <span className="fontSizeClass">29.05.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_Finance_29_05_2023.pdf" target={"_blank"}>
                                    {t("2nd list of selected candidates for the post of Medical Officer  under XV th Finance Commission, Health Department KMC on contractual basis against Adv. No H/01/KMC/2022 – 23. Dated  20.10.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">May</h4> <span className="fontSizeClass">26.05.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Quality_Manager_NUHM_26_05_2023.pdf" target={"_blank"}>
                                    {t("Written Test, Computer Skill Test & Interview against Adv. No . 12/Kolkata City NUHM Society/2022 – 23. dated 15.12.2022 for the post of Quality Manager (contractual)  under the Kolkata City NUHM Society")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">May</h4> <span className="fontSizeClass">26.05.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_26_05_2023.pdf" target={"_blank"}>
                                    {t("3rd List of selected candidates for the post of Medical Officer  for  Khidderpore U-CHC under Kolkata City NUHM Society on contractual basis against Adv. No - 7/Kolkata City NUHM Society/2022 – 23. Dated  12.11.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">May</h4> <span className="fontSizeClass">15.05.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Corrigendum_NUHM_15_05_2023.pdf" target={"_blank"}>
                                    {t("Corrigendum to Advertisement No. 01/Kolkata City NUHM Society/2023-24. Dated 12.04.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">May</h4> <span className="fontSizeClass">11.05.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Pharmacist_NUHM_11_05_2023.pdf" target={"_blank"}>
                                    {t("List of selected candidates for the Computer Test on 18.05.2023 post of Pharmacist and Laboratory Technician on contractual basis against Adv. No. 08/Kolkata City NUHM Society/2022 – 23. and 09/Kolkata City NUHM Society/2022 – 23. Dated 12.11.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">May</h4> <span className="fontSizeClass">11.05.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_11_05_2023.pdf" target={"_blank"}>
                                    {t("Walk-in Interview on 22.05.2023, 89nos. Medical officer (part time) contractual basis under the Kolkata City NUHM Society  against Adv. No . 01/Kolkata City NUHM Society/2023 – 24. Dated 12.04.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">May</h4> <span className="fontSizeClass">10.05.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Dismantling_hoarding_structure_10_05_2023.pdf" target={"_blank"}>
                                    {t("Dismantling of hoarding structure from the different premises within the jurisdiction of KMC")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">May</h4> <span className="fontSizeClass">04.05.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_04_05_2023.pdf" target={"_blank"}>
                                    {t("2nd list of selected candidates for the post of Medical Officer  for  Khidderpore U-CHC under Kolkata City NUHM Society on contractual basis against Adv. No - 7/Kolkata City NUHM Society/2022 – 23. Dated  12.11.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Apr</h4> <span className="fontSizeClass">28.04.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Ophthalmic_Assistant_Interview_28_04_2023.pdf" target={"_blank"}>
                                    {t("Interview on 04.05.2023 against Adv. No . 11/Kolkata City NUHM Society/2022 – 23. for the post of Ophthalmic Assistant contractual  for Khidderpore U-CHC under the Kolkata City NUHM Society")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Apr</h4> <span className="fontSizeClass">28.04.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Staff_Nurse_Interview_28_04_2023.pdf" target={"_blank"}>
                                    {t("Interview on 12.05.2023 & 15.05.2023, against Adv. No . 10/Kolkata City NUHM Society/2022 – 23. for  the post of Staff  Nurse contractual  for Khidderpore U-CHC under the Kolkata City NUHM Society")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Apr</h4> <span className="fontSizeClass">18.04.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_18_04_2023.pdf" target={"_blank"}>{t("2nd List of selected candidates for the post of Medical Officer for urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 13/Kolkata City NUHM Society/2022 – 23. Dated  28.01.2023")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Apr</h4> <span className="fontSizeClass">18.04.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Staff_Nurse_NUHM_18_04_2023.pdf" target={"_blank"}>{t("2nd List of selected candidates for the post of  Staff  Nurse for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No . 04/Kolkata City NUHM Society/2022 – 23. Dated 06.09.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Mar</h4> <span className="fontSizeClass">27.03.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Community_Health_Assistant_27_03_2023.pdf" target={"_blank"}>{t("Engagement of 30nos. of Community Health Assistant (Urban) contractual basis under XV Finance Commission. Advt. No. H/07/KMC/2022-23. Dated 20.10.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Mar</h4> <span className="fontSizeClass">24.03.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Staff_Nurse_Polyclinic_24_03_2023.pdf" target={"_blank"}>{t("Engagement of 17nos.Staff Nurse for Polyclinic on contractual basis under XV Finance Commission. Advt. No. H/06/KMC/2022-23. Dated 20.10.2022")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Feb</h4> <span className="fontSizeClass">23.02.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Corrigendum_Analyst_13_03_2023.pdf" target={"_blank"}>{t("Corrigendum to Advertisement No. H/04/KMC/2022-23. dated 23.02.2023.")}</a>
                                </div>
                              </li>

                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Oct</h4> <span className="fontSizeClass">20.10.2022</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_10_03_2023.pdf">{t("List of selected candidates for the post of  Medical Officer  for  urban primary health centers in Kolkata City NUHM Society areas on contractual basis against Adv. No.13/Kolkata City NUHM Society/2022 – 23. Dated  28.01.2023")}.</a>
                                </div>
                              </li>
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Oct</h4> <span className="fontSizeClass">01.10.2022</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_10_03_2023.pdf">{t("Walk-in Interview on 20.03.2023 & 21.03.2023,  01no. of  Analyst  &  01 no. of Driver contractual basis under the Health Department of Kolkata Municipal Corporation against Adv. No . H/04/KMC/2022-23 dated. 23.02.2023")}.</a>
                                </div>
                              </li>
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Oct</h4> <span className="fontSizeClass">20.10.2022</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_FinanceCommission_28_02_2023.pdf">{t("Engagement of 30 nos.Staff Nurse on contractual basis under XV Finance Commission.Advt. No. H/05/KMC/2022-23")}.</a>
                                </div>
                              </li>
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Sept</h4>
                                  <span className="fontSizeClass">06.09.2022</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/StaffNurse_Result_NUHM_24_02_2023.pdf">{t("List of selected candidates for the post of  Staff  Nurse for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No . 04/Kolkata City NUHM Society/2022 – 23")}.</a>
                                </div>
                              </li>
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Jan</h4>
                                  <span className="fontSizeClass">13.01.2023</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/MO_NUHM_22_02_2023.pdf">{t("List of selected candidates for the post of Medical Officer (Part time)  for  urban primary health centers  in Kolkata City NUHM Society areas on contractual basis against Adv. No - 06/Kolkata City NUHM Society/2022 – 23")}.</a>
                                </div>
                              </li>
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Feb</h4>
                                  <span className="fontSizeClass">20.10.2022</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/Micro_planning_Hotspots_16_06_2022.pdf">{t("Micro planning to mitigate air pollution in connection with selected Hot Spots")} </a>
                                </div>
                              </li>
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">APR</h4>
                                  <span className="fontSizeClass">26.04.2022</span>
                                </div>
                                <div className="buy-policy-2">
                                  <a className="fontSizeClass" href="https://www.kmcgov.in/KMCPortal/downloads/NUHM_MO_Rusult_31_08_2022.pdf"> {t("IHandbook for action plan to mitigate flood,cyclone & water logging-2017")}</a>
                                </div>
                              </li>
                              <li>
                                <div className="policy-img2">
                                  <h4 className="fontSizeClass">Apr</h4>
                                  <span className="fontSizeClass">01.11.2021</span>
                                </div>
                                <div className="buy-policy-2">
                                  <p className="fontSizeClass"> {t("Immersion training by ECHO India for KMC Health staff")}.</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {/* list-wrpaaer */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-6 about">
                    <div className="about-img">
                      <img src="assets/img/Banner/about.png" className="img-fluid" alt="image" />
                    </div>
                    <div className="about-img">
                      <img src="assets/img/Banner/about1.png" className="img-fluid mt-2" alt="image" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-7 col-md-12 col-sm-6 Important-Links">
                  {/* ======= F.A.Q Section ======= */}
                  <section id="faq" className="faq">
                    <div className="aos-init aos-animate" data-aos="fade-up">
                      <div className="row gy-4">
                        <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                          <div className="content">
                            <h3 className='fontSizeClass'>{t("Important Links")}</h3>
                          </div>
                          <div className="quick-part">
                            <a className="fontSizeClass" href="https://www.wb.gov.in/" target="_blank"> {t("Official Website of West Bengal Govt")}</a>
                            <a className="fontSizeClass" href="https://www.mscwb.org/" target="_blank"> {t("Municipal Service Commission")} </a>
                            <a className="fontSizeClass" href="http://wbarbanservices.gov.in/" target="_blank"> {t("Municipal Affairs Dept")}</a>
                            <a className="fontSizeClass" href="http://www.keiip.in/" target="_blank"> {t("KEIIP")} </a>
                            {/* <Link className="q-link fontSizeClass" to="/Quick_Links" target="_blank"> More<i className="bi bi-chevron-right" /></Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* End F.A.Q Section */}
                </div>
                <div className="col-lg-5 col-md-12 col-sm-6 about">
                  <div className="certificate1">
                    <h3 className='fontSizeClass'>{t("Important Links")}</h3>
                    <ul>
                      <li>
                        <a className="fontSizeClass" href="https://silpasathi.wb.gov.in/" target="_blank"> {t("Silpasathi")} </a>
                      </li>
                      <li>
                        <a className="fontSizeClass" href="http://emis.wbpcb.gov.in/airquality/citizenreport.do" target="_blank"> {t("Air Quality Information System,WBPCB")} </a>
                      </li>
                      <li>
                        <a className="fontSizeClass" href="https://bsk.wb.gov.in/citizenlogin" target="_blank"> {t("Bangla_Sahayata_Kendra_Citizen_Login")} </a>
                      </li>
                      <li>
                        <a className="fontSizeClass" href="assets/img/kmc.apk" download="kmc.apk"><i className="bi bi-download" target="_blank" /> {t("Download KMC Mobile")} {t("App for Android")} </a>
                      </li>
                      <li>
                        <a className="fontSizeClass" href="assets/img/Blind_nvda_2019_1_1.exe" download="Blind_nvda_2019_1_1.exe"><i className="bi bi-download" target="_blank" /> {t("Download NVDA for the Accessibility of Visually Challenged User")} </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Home;
