import React from "react";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const NavBar = () => { 
  const fileDownload=(fileStoreId)=>
  {
    
    let url=`https://kmcdev.ddns.net/pwc-db-services/getPortalFiles`
     axios
        .post(url,{"fileId": fileStoreId, "userId": "392" })
        .then((res) => {
            console.log("response is --- ", res);

            if(res && res.data && res.status === 200) {
              const base64 = res.data[0] && res.data[0].base64_data;
              const pdfData = 'data:application/pdf;base64,'+base64;

              let html = '<html>' +
                  '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
                  '<body>' +
                  '<iframe src="' + pdfData + '"></iframe>' +
                  '</body></html>';
                  console.log(html);
              let a = window.open();
              a.document.write(html);
          }
        })
        .catch((e) => {
            // setLoader(false);
            console.log("The error is ", e);
        })
  }
  const changeLanguage = (event) => {
    let lng=event.target.value
    console.log(lng,"lng")
    i18n.changeLanguage(lng);
  };
  const [click, setClick] = React.useState(false);
  const [citizenServices, setcitizenServices] = React.useState(false);
  const [municipalHistory, setmunicipalHistory] = React.useState(false);
  const [aboutus, setaboutus] = React.useState(false);
  const [financialstatement, setfinancialstatement] = React.useState(false);
  const [financialstatement1, setfinancialstatement1] = React.useState(false);
  const [financialstatement2, setfinancialstatement2] = React.useState(false);
  const [financialstatement3, setfinancialstatement3] = React.useState(false);
  const [financialstatement4, setfinancialstatement4] = React.useState(false);
  const [financialstatement5, setfinancialstatement5] = React.useState(false);
  const [financialstatement6, setfinancialstatement6] = React.useState(false);
  const [financialstatement7, setfinancialstatement7] = React.useState(false);
  const [AdvertisementServie, setAdvertisementServie] = React.useState(false);
  const [AmusementService, setAmusementService] = React.useState(false);
  const [BusteeServices, setBusteeServices] = React.useState(false);
  const [MakeOnline, setMakeOnline] = React.useState(false);

  const [BuildingServices, setBuildingServices] = React.useState(false);
  const [BirthDeathService, setBirthDeathService] = React.useState(false);

  const [ChVSDepartmentService, setChVSDepartmentService] = React.useState(false);
  const [CarParkingService, setCarParkingService] = React.useState(false);

  const [AssessmentCollectionService, setAssessmentCollectionService] = React.useState(false);

  const [HeritageService, setHeritageService] = React.useState(false);
  const [MarketService, setMarketService] = React.useState(false);
  const [LightingService, setLightingService] = React.useState(false);
  const [LicenseService, setLicenseService] = React.useState(false);
  const [EducationService, setEducationService] = React.useState(false);
  const [KMCSchoolService, setKMCSchoolService] = React.useState(false);

  const [SocialWelfareService, setSocialWelfareService] = React.useState(false);
  const [SewerageDrainageService, setSewerageDrainageService] = React.useState(false);
  const [HealthService, setHealthService] = React.useState(false);
  const [ParksSquaresService, setParksSquaresService] = React.useState(false);
  const [SolidWeastService, setSolidWeastService] = React.useState(false);
  const [WaterSupplyService, setWaterSupplyService] = React.useState(false);
  const [CentralRecordsService, setCentralRecordsService] = React.useState(false);
  const [language, setlanguage] = React.useState(false);
  const [languageSelect, setlanguageSelect] = React.useState(false);

  const [login, setlogin] = React.useState(false);
  const { t, i18n, ready } = useTranslation();
  const handleClick = () => {
    click
      ? document.body.classList.remove("modal-open")
      : document.body.classList.add("modal-open");
    setClick(!click);
  };

  const HeritageServiceToggle = () => {
    setHeritageService(!HeritageService);
  };

  const SolidWeastServiceToggle = () => {
    setSolidWeastService(!SolidWeastService);
  };

  const MarketServiceToggle = () => {
    setMarketService(!MarketService);
  };

  const LightingServiceToggle = () => {
    setLightingService(!LightingService);
  };

  const LicenseServiceToggle = () => {
    setLicenseService(!LicenseService);
  };
  const KMCSchoolServiceToggle = () => {
    setKMCSchoolService(!KMCSchoolService);
  };

  const EducationServiceToggle = () => {
    setEducationService(!EducationService);
  };

  const CentralRecordsServiceToggle = () => {
    setCentralRecordsService(!CentralRecordsService);
  };

  const HealthServiceToggle = () => {
    setHealthService(!HealthService);
  };

  const SocialWelfareServiceToggle = () => {
    setSocialWelfareService(!SocialWelfareService);
  };

  const SewerageDrainageServiceToggle = () => {
    setSewerageDrainageService(!SewerageDrainageService);
  };

  const ParksSquaresServiceToggle = () => {
    setParksSquaresService(!ParksSquaresService);
  };

  const ChVSDepartmentServiceToggle = () => {
    setChVSDepartmentService(!ChVSDepartmentService);
  };

  const CarParkingServiceToggle = () => {
    setCarParkingService(!CarParkingService);
  };

  const AssessmentCollectionServiceToggle = () => {
    setAssessmentCollectionService(!AssessmentCollectionService);
  };

  const WaterSupplyServiceToggle = () => {
    setWaterSupplyService(!WaterSupplyService);
  };

  const AmusementServiceToggle = () => {
    setAmusementService(!AmusementService);
  };

  const BusteeServicesToggle = () => {
    setBusteeServices(!BusteeServices);
  };

  const MakeOnlineToggle = () => {
    setMakeOnline(!MakeOnline);
  };

  const BirthDeathServiceToggle = () => {
    setBirthDeathService(!BirthDeathService);
  };

  const BuildingServicesToggle = () => {
    setBuildingServices(!BuildingServices);
  };

  const citizenServicesToggle = () => {
    setcitizenServices(!citizenServices);
  };

  const AdvertisementServieToggle = () => {
    setAdvertisementServie(!AdvertisementServie);
  };

  const financialstatementToggle = () => {
    setfinancialstatement(!financialstatement);
  };

  const financialstatementToggle1 = () => {
    setfinancialstatement1(!financialstatement1);
  };
  const financialstatementToggle2 = () => {
    setfinancialstatement2(!financialstatement2);
  };
  const financialstatementToggle3 = () => {
    setfinancialstatement3(!financialstatement3);
  };
  const financialstatementToggle4 = () => {
    setfinancialstatement4(!financialstatement4);
  };
  const financialstatementToggle5 = () => {
    setfinancialstatement5(!financialstatement5);
  };
  const financialstatementToggle6 = () => {
    setfinancialstatement6(!financialstatement6);
  };
  const financialstatementToggle7 = () => {
    setfinancialstatement7(!financialstatement7);
  };

  const loginToggle = () => {
    setlogin(!login);
  };
  const languageToggle = () => {
    setlanguage(!language);
  };
  const languageSelectToggle = () => {
    setlanguageSelect(!languageSelect);
  };

  const aboutusToggle = () => {
    setaboutus(!aboutus);
  };

 

  const municipalHistoryToggle = () => {
    setmunicipalHistory(!municipalHistory);
  };

  const Close = () => {
    setClick(false);
  };

  

  return ( 
    <div className="NavBar_container">
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="NavBar_container--navbar" onClick={(e) => e.stopPropagation()}  >
      <div className="nav_container--item">
        <ul className={click ? "nav-menu active" : "nav-menu"}>
        <Link className="nav-item-list homebtn border_bottom py-0" to='/portal' onClick={handleClick}> <i class="bi bi-house-door"></i> {t("Home")} </Link>
        <Link className="nav-item-list homebtn border_bottom py-0" to='/Quick_Links' onClick={handleClick}> {t("Quick Links")} </Link>
        <Link className="nav-item-list homebtn border_bottom py-0" to="/Dashboard" onClick={handleClick}> {t("EODB Dashboard")} </Link>
        <li className="nav-item-list border_bottom">
            <div id="accordion">
            <div className="">
              <div className="card-header responsiveheader" id="heading1" >
                <button className="nav-item-list homebtn fontSizeClass py-0" onClick={languageToggle} data-toggle="collapse" data-target="#homebtn" aria-expanded="false" aria-controls="homebtn"  >
                {t("Online Payment1")} <i className="bi bi-chevron-down float_right text-white" /></button>
              </div>
                <div  id="homebtn"  className={language?"collapse show":"collapse "}  data-parent="#accordion">
                    <div className="card-body m-3 responsivemenudiv">
                    <a className="dropdown-item" href="https://www.kmcgov.in/KMCPortal/jsp/KMCAssesmentAllUnpaidDemand.jsp" onClick={handleClick} target="_blank"> {t("All Bill")}</a>
                    <a className="dropdown-item" href="https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp?callbackURL=/KMCPortal/jsp/MiscellaneousSuspensePay.jsp" onClick={handleClick}target="_blank"> {t("Suspense")} </a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/LOIDemandWaiverFiftyPrint.jsp' onClick={handleClick} target="_blank"> {t("Upto 50% Interest Waive LOI")}</a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp' onClick={handleClick} target="_blank"> {t("UAA Online Submission")}</a>
                    <a className="dropdown-item navcol" href="https://www.kmcgov.in/KMCPortal/jsp/KMCBuildingPayment.jsp" onClick={handleClick}target="_blank"> {t("Building")}</a>
                    <a className="dropdown-item navcol" href="https://www.kmcgov.in/KMCPortal/KMCPortalLicensePaymentAction.do" onClick={handleClick} target='_blank'> {t("CE (Licence Dept.)")}</a>
                    <a className="dropdown-item navcol" href="https://www.kmcgov.inKMCPortal/jsp/SorDemandPayment.jsp"onClick={handleClick} target='_blank'> {t("Ch. Valuer & Surveyor")}</a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketPayment.jsp'onClick={handleClick}> {t("Regular Demand")} </a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/MarketMiscPayDemand.jsp'onClick={handleClick}> {t("Misclennious Demand")} </a>
                    <li className="nav-item">
                      <a class="nav-link" onClick={(e)=>fileDownload('50')}> {t("Amusement Fees")} </a> </li>                                 
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'onClick={handleClick}> {t("Casual Demand Generation")} {t("and payment")} </a>
                    <Link className="dropdown-item navcol" to='/Car_Parking_Home'onClick={handleClick}> {t("Parking")} </Link>
                    <Link className="dropdown-item navcol" to='/KMC_Advertisement'onClick={handleClick}> {t("Advertisement")} </Link>
                    <Link className="dropdown-item navcol" to='/Water_Supply'onClick={handleClick}> {t("Water Supply")}</Link>
                    <Link className="dropdown-item navcol" to='/Drainage_Home'onClick={handleClick}> {t("Drainage")} </Link>
                    <Link className="dropdown-item navcol" to='/KMC_Bustee_services'onClick={handleClick}> {t("Bustee")}</Link>
                </div>
              </div>
            </div>
            </div>
        </li>
        <a className="nav-item-list homebtn border_bottom py-0"onClick={handleClick} href="https://www.kmcgov.in/KMCPortal/jsp/AppellateAuthorities.jsp"> {t("RTI")} </a>

         <li className="nav-item-list border_bottom">
            <div id="accordion">
            <div className="">
              <div className="card-header responsiveheader" id="heading2">
                <button className="nav-item-list homebtn fontSizeClass py-0" onClick={municipalHistoryToggle} data-toggle="collapse" data-target="#MunicipalHistorybtn" aria-expanded="false" aria-controls="MunicipalHistorybtn"  >
                {t("Tender")} <i className="bi bi-chevron-down float_right text-white" /></button>
              </div>
              <div  id="MunicipalHistorybtn"  className={municipalHistory?"collapse show":"collapse "}  data-parent="#accordion">
                <div className="card-body m-3 responsivemenudiv">
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/KMCOtherTenders.jsp'onClick={handleClick}> {t("Tender")} </a>
                    <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/KMCTender.jsp?Code=Y'onClick={handleClick}> {t("Tender Barta")} </a>
                    <a className="dropdown-item" onClick={(e)=>fileDownload('284')}> {t("Tender Form Format")} </a>                  
                </div>
              </div>
            </div>
            </div>
         </li>

          <li className="nav-item-list border_bottom">
            <div id="accordion">
                <div className="">
                  <div className="card-header responsiveheader" id="heading3">
                    <button className="nav-item-list homebtn fontSizeClass py-0" onClick={citizenServicesToggle} data-toggle="collapse" data-target="#citizenServices" aria-expanded="false" aria-controls="citizenServices"  >
                    {t("Online Services")} <i className="bi bi-chevron-down float_right text-white" /></button>
                  </div>
                  <div  id="citizenServices"  className={citizenServices?"collapse show":"collapse "}  data-parent="#accordion">
                    <div className="card-body m-3 responsivemenudiv">
                      <a className="dropdown-item ser-log1" href='https://www.kmcgov.in/KMCPortal/jsp/UserLoginPage.jsp'  target="_blank"> {t("eKMC 1.0 Login")}</a>
                      <Link className="dropdown-item" to='/KMC_Advertisement'onClick={handleClick}> {t("Advertisement")}</Link>
                      <Link className="dropdown-item" to='/Amusement'onClick={handleClick}> {t("Amusement")}</Link>
                      <Link className="dropdown-item" to='/Assessment_Collection_Assessment_Home'onClick={handleClick}> {t("Assessment Collection")} </Link>
                      <Link className="dropdown-item" to='/Birth_Death_Ragistration'onClick={handleClick}> {t("Birth/Death Registration")} </Link>
                      <Link className="dropdown-item" to='/Building_Sanction_Building_Home'onClick={handleClick}> {t("Building Sanction")} </Link>
                      <Link className="dropdown-item" to='/KMC_Bustee_services'onClick={handleClick}> {t("Bustee Services")}</Link>
                      <Link className="dropdown-item" to='/Car_Parking_Home'onClick={handleClick}> {t("Car Parking")}</Link>
                      <Link className="dropdown-item" to='/Central_records_home'onClick={handleClick}> {t("Central Records")}</Link>
                      <Link className="dropdown-item" to='/CH_VS_Dept_Download_Forms'onClick={handleClick}> {t("CH.VS Dept")}</Link>
                      <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/KMCPortalDrainagePayment.jsp'onClick={handleClick}> {t("Drainage")}</a>
                      <Link className="dropdown-item" to='/Kmc_school_home'onClick={handleClick}> {t("Education")}</Link>
                      <Link className="dropdown-item" to='/Engineering_Home'onClick={handleClick}> {t("Engineering (Civil)")} </Link>
                      <Link className="dropdown-item" to='/Health_services_Health_Home'onClick={handleClick}> {t("Health Services")}</Link>
                      <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/HeritageBuildingHome.jsp'onClick={handleClick}> {t("Environment & Heritage")}</a>
                      <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/ContactDept_EWaste.jsp'onClick={handleClick}>{t("Information Technology")}</a>
                      <Link className="dropdown-item" to='/Kmc_school_home'onClick={handleClick}> {t("KMCP Schools")} </Link>
                      <Link className="dropdown-item" to='/Certificate_of_Enlistment'onClick={handleClick}> {t("Licence")} </Link>
                      <Link className="dropdown-item" to='/Lighting_Home'onClick={handleClick}> {t("Lighting")}</Link>
                      <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/KMCMarketHome.jsp'onClick={handleClick}> {t("Market")} </a>
                      <a className="dropdown-item" href='https://www.kmcgov.in/KMCPortal/jsp/KMCParksGardens1.jsp'onClick={handleClick}> {t("Parks & Squares")}</a>
                      <Link className="dropdown-item" to='/Drainage_Home'onClick={handleClick}>{t("Sewerage & Drainage")}</Link>
                      <Link className="dropdown-item" to='/Social_welfare_Download_Forms'onClick={handleClick}> {t("Social Welfare & Urban")} <br /> {t("Poverty Alleviation")}</Link>
                      <Link className="dropdown-item" to='/Solid_wastes_home'onClick={handleClick}> {t("Solid Waste Management")}</Link>
                      <Link className="dropdown-item" to='/Water_Supply'onClick={handleClick}> {t("Water Supply")}</Link>
                    </div>
                  </div>
                </div>
            </div>
          </li>

          <a className="nav-item-list homebtn border_bottom py-0" href="https://www.kmcgov.in/KMCPortal/jsp/UserRegistration.jsp" onClick={handleClick} target="_blank">{t("Register KYC")}</a>
          <Link className="nav-item-list homebtn border_bottom py-0" to="/Contact_Us_Home" onClick={handleClick}> {t("Contact")} </Link>

          <li className="nav-item-list border_bottom">
            <div id="accordion">
                <div className="">
                  <div className="card-header responsiveheader" id="heading4">
                    <button className="nav-item-list homebtn fontSizeClass py-0" onClick={aboutusToggle} data-toggle="collapse" data-target="#aboutus" aria-expanded="false" aria-controls="aboutus"  >
                    {t("More")} <i className="bi bi-chevron-down float_right text-white" /></button>
                  </div>
                  <div  id="aboutus"  className={aboutus?"collapse show":"collapse "}  data-parent="#accordion">
                    <div className="card-body m-3 responsivemenudiv">
                      <a className="dropdown-item" href=''onClick={handleClick}> {t("Submenu 1")} </a>
                      <a className="dropdown-item" href=''onClick={handleClick}> {t("Submenu 2")}</a>
                      <a className="dropdown-item" href=''onClick={handleClick}> {t("Submenu 3")} </a>         
                    </div>
                  </div>
                </div>
            </div>
          </li>           
            </ul>
            <div className="nav-icon" onClick={handleClick}>
            {click ? <AiOutlineClose/> : <FiMenu/>} 

            </div>
          </div>
        </nav>
      </ div>
    );


}

export default NavBar;
